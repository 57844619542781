
<!-- HEADER -->
<nav class="navbar fixed-top bg-white">
  <div class="container">
    <a class="navbar-brand" href="{{baseURL}}" target="_blank">
      <img src="./assets/images/logo.svg" class="d-inline-block align-top" alt="Payd">
    </a>
    <form id="nav-form" class="form-inline float-right d-none d-md-block d-lg-block nav-form">
      <button routerLink="/sign-up" class="btn btn-outline-primary ml-3 p-2 pl-4 pr-4 main" type="button">SIGN UP</button>
      <button routerLink="/login" class="btn btn-primary ml-3 p-2 pl-4 pr-4 main" type="button">LOGIN</button>
      <a class="nav-item nav-link profile d-none" routerLink="/profile"><h6 class="pro-name mt-2">Hello {{displayName}}!</h6></a>
      <a class="nav-item nav-link profile d-none" routerLink="" (click)=signOut()><h6 class="sign-out mt-2">Sign Out</h6></a>
    </form>

    <!-- Mobile Slide Menu From Left to Right -->
    <button class="navbar-toggler navbar-toggler-right collapsed" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="close"><i class="fas fa-times"></i></span>
      <span class="navbar-toggler-icon mt-2"><i class="fas fa-bars"></i></span>
    </button>

    <div class="collapse navbar-collapse bg-purple" id="navbarCollapse">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item main">
          <a class="nav-item nav-link main" routerLink="/sign-up">Sign Up</a>
        </li>
        <li class="nav-item profile d-none">
          <a class="nav-item nav-link " routerLink="/profile">Hello {{displayName}}!</a>
        </li>
        <li class="nav-item main">
          <a class="nav-item nav-link" routerLink="/login">Login</a>
        </li>
        <li class="nav-item profile d-none">
          <a class="nav-item nav-link profile d-none" routerLink="" (click)=signOut()>Sign Out</a>
        </li>
      </ul>
    </div>

  </div>
</nav>
