import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
// @ts-ignore
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {NotifierModule} from 'angular-notifier';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {EncryptDecryptService} from './services/encrypt-decrypt.service';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {DataTablesModule} from 'angular-datatables';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {ForgetPasswordComponent} from './forget-password/forget-password.component';
import {NavigationComponent} from './navigation/navigation.component';
import {MyAccountComponent} from './my-account/my-account.component';
import {PaymentAckComponent} from './payment-ack/payment-ack.component';
import {CardFailedComponent} from './card-failed/card-failed.component';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {MomentModule} from 'angular2-moment';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';

import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SaveCardFailedSignupComponent } from './save-card-failed-signup/save-card-failed-signup.component';
import { SaveCardSuccessComponent } from './save-card-success/save-card-success.component';
import { SaveCardSuccessMbComponent } from './save-card-success-mb/save-card-success-mb.component';
import { SaveCardFailedMbComponent } from './save-card-failed-mb/save-card-failed-mb.component';
import { DatepickerModule, BsDatepickerModule  } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common'

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    ForgetPasswordComponent,
    NavigationComponent,
    MyAccountComponent,
    PaymentAckComponent,
    CardFailedComponent,
    SaveCardFailedSignupComponent,
    SaveCardSuccessComponent,
    SaveCardSuccessMbComponent,
    SaveCardFailedMbComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CollapseModule.forRoot(),
    HttpClientModule,
    NotifierModule,
    RouterModule,
    DataTablesModule,
    NgbModule,
    NgIdleKeepaliveModule.forRoot(),
    MomentModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    Ng2SearchPipeModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [EncryptDecryptService,DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule {
}
