import { Component, OnInit } from '@angular/core';
import {environment} from './../../environments/environment.prod';
import {NotifierService} from "angular-notifier";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import cache from 'memory-cache';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
  baseURL;
  displayName: string;
  private notifier: NotifierService;

  constructor(private router: Router, private http: HttpClient, notifier: NotifierService) {
    this.baseURL = environment.baseURL;
    this.notifier = notifier;
  }

  ngOnInit() {
    this.displayName = localStorage.getItem('displayName');
  }

  signOut() {
    cache.del('displayName');
    cache.del('slug');
    // cache.clear();

    localStorage.clear();
    location.href = '';

    localStorage.setItem('isApprovedViewed', 'true');
    localStorage.setItem('isNotApprovedViewed', 'true');
  }
}
