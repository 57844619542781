import { Component, OnInit } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Router } from '@angular/router';
import {SwUpdate} from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'Payd';
  isCollapsed = true;
  timedOut = false;
  lastPing?: Date = null;

  constructor(private router: Router, private idle: Idle, private keepalive: Keepalive,
              private swUpdate: SwUpdate){
    this.reset();
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  ngOnInit() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        window.location.reload();
      });
    }
  }
}
