<app-navigation></app-navigation>
<div class="content-sec">
  <div class="container">

    <notifier-container></notifier-container>
    <div class="card shadow-md-lg border-md-0 mb-3 mb-5">
      <div class="row no-gutters">
        <div class="col-md-6 d-none d-md-block d-lg-block">
          <div class="login-bg">
            <img src="./assets/images/login-bg.png" class="card-img img-fluid" alt="login-background">
          </div>
        </div>
        <div class="col-md-6">
          <div class="card-body text-center p-3 ml-md-0 mr-md-0 ml-lg-5 mr-lg-5 mt-0 mt-md-3">
            <img class="logform-logo mb-3 mt-2" src="./assets/images/logo-v.svg" width="75" alt="logo">
            <h1 class="card-title ml-md-0 mr-md-0 mt-3 mb-5">WELCOME BACK</h1>

            <form class="ml-md-5 mr-md-5 mb-2" [formGroup]="loginForm">
              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-envelope"></i></span>
                  </div>
                  <input type="email" class="form-control" placeholder="Email" aria-label="Email" [(ngModel)]="email"
                         aria-describedby="basic-addon1" formControlName="email"
                         [ngClass]="{'is-invalid': loginForm.get('email').touched && loginForm.get('email').invalid}">
                  <div *ngIf="loginForm.get('email').touched && loginForm.get('email').invalid"  class="invalid-feedback text-right">
                    <div *ngIf="loginForm.get('email').errors.required">This field is required.</div>
                    <div *ngIf="loginForm.get('email').errors.pattern">Please enter a valid email address.</div>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon2"><i class="fas fa-lock"></i></span>
                  </div>
                  <input type="password" class="form-control" placeholder="Password" aria-label="Password" [(ngModel)]="password"
                         aria-describedby="basic-addon1" formControlName="password"
                         [ngClass]="{'is-invalid': loginForm.get('password').touched && loginForm.get('password').invalid}">
                  <div *ngIf="loginForm.get('password').touched && loginForm.get('password').invalid" class="invalid-feedback text-right">
                    <div *ngIf="loginForm.get('password').errors.required">This field is required.</div>
                  </div>
                </div>
              </div>

              <p class="card-text forget-pwd float-right"><a routerLink="/forgot-password"><small class="text-muted">Forgot Password</small></a></p>
              <button type="submit" class="btn btn-primary w-100 p-2 mt-1" [disabled]="!loginForm.valid" (click)=login($event.target)>LOGIN</button>
            </form>
            <p class="card-text">
              <small class="text-muted">Don't have an account?</small>
              <small class="text-muted"><a routerLink="/sign-up"><strong> Sign Up </strong></a></small>
              <small class="text-muted">here</small>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

