import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {ForgetPasswordComponent} from './forget-password/forget-password.component';
import {MyAccountComponent} from './my-account/my-account.component';
import {PaymentAckComponent} from './payment-ack/payment-ack.component';
import {CardFailedComponent} from './card-failed/card-failed.component';
import {SaveCardFailedSignupComponent} from './save-card-failed-signup/save-card-failed-signup.component';
import {SaveCardSuccessComponent} from './save-card-success/save-card-success.component';
import {SaveCardSuccessMbComponent} from './save-card-success-mb/save-card-success-mb.component';
import {SaveCardFailedMbComponent} from './save-card-failed-mb/save-card-failed-mb.component';


const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {path: 'sign-up', component: SignupComponent},
  {path: 'forgot-password', component: ForgetPasswordComponent},
  {path: 'profile', component: MyAccountComponent},
  // save card success - sign up
  {path: 'acknowledgement', component: PaymentAckComponent},
  // save card failed - sign up
  {path: 'account-acknowledgement', component: SaveCardFailedSignupComponent},
  // save card success - profile
  {path: 'save-card-success', component: SaveCardSuccessComponent},
  // save card failed - profile
  {path: 'save-card-failed', component: CardFailedComponent},
  // save card success - mobile app
  {path: 'save-card-success-mb', component: SaveCardSuccessMbComponent},
  // save card failed - mobile app
  {path: 'save-card-failed-mb', component: SaveCardFailedMbComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
