import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {LoginService} from '../services/login.service';
import {TokenService} from '../services/token.service';
import {NotifierService} from 'angular-notifier';
import {HttpClient} from '@angular/common/http';
import cache from 'memory-cache';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  email: string;
  password: string;
  displayName: string;

  errorMsg: string;
  ipAddress: string;
  ipv4Address: string;
  congitoUser = new BehaviorSubject(null);

  loadingBtn;

  accessToken: string;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$')]),
    password: new FormControl('', [Validators.required])
  });

  /**
   * Notifier service
   */
  private notifier: NotifierService;

  // constructor(private router: Router) {
  constructor(private router: Router, private http: HttpClient, private loginService: LoginService,
              private tokenService: TokenService, notifier: NotifierService) {
    this.notifier = notifier;

    // this.amplifyService.authStateChange$
    //   .subscribe(authState => {
        if (cache.get('slug') === null && localStorage.getItem('slug') == null) {
          console.log(cache.get('slug'));
          this.router.navigate(['/login']);
        } else {
          this.router.navigate(['/profile']);
        }
  }

  ngOnInit() {
    if (this.loginForm.valid) {
      this.loginForm.markAsUntouched();
    }

    this.getIP();
  }

  public getIP() {
    this.loginService.getIPAddress().subscribe((res: any) => {
      this.ipAddress = res.ip;
      localStorage.setItem('ipAddress', this.ipAddress);
    });
  }

  // on step 1 sign up button click
  async login(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;

    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();

    promise1.then((Response1) => {

      if (Response1.data === null) {

        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.loginUserStep1(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.loginUserStep1(this.accessToken);
      }

    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }


// check email and password against BE DB
  loginUserStep1(accessToken) {

    this.loginService.loginUser(accessToken, this.email, this.password).subscribe(Response => {
      if (Response.successCode) {
        this.notifier.notify('success', Response.message);

        cache.put('displayName', Response.data['customer'].first_name);
        cache.put('slug', Response.data['user'].slug);
        // console.log(cache.get('displayName'));
        // console.log(cache.get('slug'));

        localStorage.setItem('displayName', Response.data['customer'].first_name);
        localStorage.setItem('isApproved', Response.data['customer'].is_approved);
        localStorage.setItem('slug', Response.data['user'].slug);
        localStorage.removeItem('resentVerification');
        this.router.navigate(['/profile']);
      } else {
        this.notifier.notify('error', Response.message);
        // console.log('Response Error');
        setTimeout(() => {
          this.loadingBtn.innerHTML = 'LOGIN';
          this.loadingBtn.disabled = false;
        }, 500);
      }
    });
  }
}
