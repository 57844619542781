const isProd = window.location.hostname === 'http://localhost:4200/' || window.location.hostname === 'www.payd.com';

export const environment = {
  production: true,
  apiURL: isProd ? 'https://paydread.com/' : 'http://apiwrite.retailbizz.io/',
  accessKeyId: 'AKIAY6JOGXQ5JS7QCQX7',
  secretAccessKey: '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',
  region: 'ap-southeast-1',
  baseURL: isProd ? 'https://customer.retailbizz.io' : 'https://customer.retailbizz.io'
};
