<!-- HEADER SECTION -->
<app-navigation></app-navigation>
<!-- BODY SECTION -->
<div class="content-sec">
  <div class="container">

    <notifier-container></notifier-container>

    <div class="card shadow-md-lg border-md-0 mb-3">
      <div class="row no-gutters add-card-gutter">

        <div class="col-md-4 col-sm-12">
          <div class="side-bar">
            <div class="d-none d-sm-block user-info p-3 mb-2 text-center">
              <div class="row">
                <div class="col-sm-12 col-5">
                  <div class="pro-profile d-block ml-auto mr-auto w-50 h-100">
                    <img class="rounded-circle" src="./assets/images/user.svg" alt="User">
                  </div>
                </div>
                <div class="col-sm-12 col-7">
                  <div class="mt-4">
                    <h5 class="card-title text-left text-sm-center">{{displayName}}</h5>
                  </div>
                </div>
                <div class="col-sm-12 col-6 offset-5 offset-sm-0">
                  <div class="text-tier mt-1 mb-5 text-left text-sm-center">
                    <span class="icon-star mr-2"><i class="fas fa-star fa-lg"></i></span> Gold Member
                  </div>
                </div>
              </div>
            </div>

            <div class="profile-links mb-sm-5">
              <div class="list-group">

                <div class="lists pro-link mb-sm-3">
                  <a [ngClass]="[listGroupItemClass, activeOverview]" (click)="clickOverview()">
                    <div class="list-icon pro-profile-info">
                      <span class="side-menu-item"><i class="bi bi-grid"></i></span>
                      Overview
                    </div>
                  </a>
                </div>
                <div class="lists pro-link mb-sm-3">
                  <a [ngClass]="[listGroupItemClass, activeProfile]" (click)="clickProfile()">
                    <div class="list-icon pro-profile-info">
                      <span class="side-menu-item"><i class="bi bi-person"></i><span *ngIf="!isApprovedViewed || !isNotApprovedViewed" class="notification-dot"></span></span>
                      Profile
                    </div>
                  </a>
                </div>
                <div class="lists order-link mb-sm-3">
                  <a [ngClass]="[listGroupItemClass, activeOrder]" (click)="clickOrders()">
                    <div class="list-icon pro-orders">
                      <span class="side-menu-item"><i class="bi bi-card-list"></i></span>
                      Orders
                    </div>
                  </a>
                </div>
                <div class="lists card-link mb-sm-3">
                  <a [ngClass]="[listGroupItemClass, activeMyCard]" (click)="clickMyCard()">
                    <div class="d-block d-sm-none list-icon pro-my-card">
                      <span class="side-menu-item"><i class="bi bi-credit-card"></i></span>
                      Cards
                    </div>
                    <div class="d-none d-sm-block list-icon pro-my-card">
                      <span class="side-menu-item"><i class="bi bi-credit-card"></i></span>
                      My Cards
                    </div>
                  </a>
                </div>
                <div class="lists order-link mb-sm-3 d-none d-sm-block">
                  <a [ngClass]="[listGroupItemClass]" href="https://www.payd.retailbizz.io/contact-us" target="_blank">
                    <div class="list-icon pro-orders">
                      <span class="side-menu-item"><i class="bi bi-headset"></i></span>
                      Support
                    </div>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div class="col-md-8 col-sm-12">
          <div class="card-body text-center p-3 ml-md-0 mr-md-0 ml-lg-4 mr-lg-4">

            <!-- OVERVIEW DETAIL -->
            <div *ngIf="isOverview" class="profile-detail">

              <div class="profile-overview">
                <h5 class="card-title text-left">OVERVIEW</h5>
                <hr class="mt-1 mb-4">
                <div class="row">
                  <div class="col-md-6">
                    <div class="card child mb-3">
                      <div class="card-body d-xl-flex">

                        <div class="child-txt text-left ml-2">
                          <p class="card-text">Available balance to spend</p>
                          <h5 class="card-title">{{avilableCredit}} LKR</h5>
                        </div>
                        <img class="h-100" src="./assets/images/overview-1.svg" alt="card balance img">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="card child mb-3">
                      <div class="card-body d-xl-flex">

                        <div class="child-txt text-left ml-2">
                          <p class="card-text">Your next payment due</p>
                          <h5 class="card-title">{{nextInstallmentDate}}</h5>
                        </div>
                        <img class="h-100" src="./assets/images/overview-2.svg" alt="payment due img">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="profile-head ml-md-0 mr-md-0 mt-4 mt-sm-3 mb-3">
                <h5 class="card-title text-left">RECENT TRANSACTIONS</h5>
              </div>
              <hr class="mt-1 mb-4">

              <div class="loading text-center font-weight-500 p-3" *ngIf="showTable == false">
                <span><i class="fa fa-spinner fa-spin mr-2"></i> Please wait...</span>
              </div>

              <div *ngIf="showTable == true">
                <div class="profile-detail-table  table-responsive-xl mt-3 mb-5" *ngIf="overviewResponseDataArray?.length != 0">
                  <table datatable [dtOptions]="dtOptionsRT" class="table table-condensed dt-responsive"
                         style="width: 100%!important">
                    <thead class="p-2">
                      <tr>
                        <td>
                          <div class="row">
                            <div class="col-6 col-sm-3 col-md-3">
                              Order Ref
                            </div>
                            <div class="col-6 col-sm-3 col-md-3">
                              Transaction Date
                            </div>
                            <div class="col-6 col-sm-3 col-md-3">
                              Merchant
                            </div>
                            <div class="col-6 col-sm-3 col-md-3">
                              Amount
                            </div>
                          </div>
                        </td>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let overview of overviewResponseDataArray">
                        <td>
                          <div class="item-list">
                            <div class="space-tr">
                              <div class="row">
                                <div class="col-6 col-sm-3 col-md-3">
                                  <input type="hidden" value="#123456">
                                  <div class="order-ref-number bg-light rounded p-2">
                                    <span>#{{overview.order_id}}</span></div>
                                </div>
                                <div class="col-6 col-md-3">
                                  <div class="mt-1">{{overview.paid_date}}</div>
                                </div>
                                <div class="col-6 col-md-3">
                                  <div class="mt-1">{{overview.merchant_name}}</div>
                                </div>
                                <div class="col-6 col-sm-3 col-md-3 mt-2 mt-md-0">
                                  <div class="mt-1">LKR {{overview.paid_amount}}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="profile-detail-table mt-4 mb-5" *ngIf="overviewResponseDataArray?.length == 0">
                <h5 class="text-muted">No records found!</h5>
              </div>

            </div>


            <!-- PROFILE DETAIL -->
            <div *ngIf="isProfile" class="profile-detail">
              <div class="profile-head ml-md-0 mr-md-0 mt-5 mb-1">
                <div class="row">
                  <div class="col-6 col-sm-6">
                    <h5 class="card-title text-left">PROFILE</h5>
                  </div>
                  <div class="col-6 col-sm-6">
                    <a *ngIf="isApproved == '0' || isApproved == '1'" href="#editProfile"
                       class="btn btn-primary float-right" data-toggle="modal"><i
                      class="fas fa-pen mr-2"></i>EDIT INFO</a>

                    <a *ngIf="isApproved == '2'" href="#rejectedProfileEdit" class="btn btn-primary float-right"
                       data-toggle="modal"><i
                      class="fas fa-pen mr-2"></i>EDIT INFO</a>
                  </div>
                </div>
              </div>
              <hr class="mt-1 mb-4">

              <!-- Show account approved or not notification once until they sign out-->
              <div class="alert alert-warning alert-dismissible fade show text-left" role="alert" *ngIf="isApproved == '2' && isNotApprovedViewed == 'false'">
                <strong><i class="fas fa-info-circle mr-1"></i></strong> We're processing your account. We'll notify you via email when your account is approved for transactions.
              </div>

              <div class="alert alert-success alert-dismissible fade show text-left" role="alert" *ngIf="isApproved == '1' && isApprovedViewed == 'false'">
                <strong><i class="fas fa-check-circle mr-1"></i> Hooray! </strong>Your account is approved. Now you can buy now and pay later!
              </div>

              <div class="profile-detail-table mt-4 mb-4">
                <div class="row">
                  <div class="col-md-6 col-sm-12">
                    <div class="sub-item mb-3 mb-md-4">
                      <p class="text-muted text-left mb-1 mb-md-2">First Name</p>
                      <h6 class="text-left">{{customerFirstName}}</h6>
                    </div>
                    <div class="sub-item mb-3 mb-md-4">
                      <div class="email-verify" *ngIf="customerEmailVerified == 0">
                        <a *ngIf="isApproved == '2'" href="#verifyEmail"
                           class="btn btn-outline-primary float-right disabled" data-toggle="modal"
                           (click)="sendOtp()">VERIFY</a>
                        <a *ngIf="isApproved == '1'" href="#verifyEmail" class="btn btn-outline-primary float-right"
                           data-toggle="modal"
                           (click)="sendOtp()">VERIFY</a>
                        <p class="text-muted text-left mb-1 mb-md-2">Email</p>
                      </div>
                      <div class="email-verify" *ngIf="customerEmailVerified == 1"><a
                        class="btn btn-verified float-right">VERIFIED</a>
                        <p class="text-muted text-left mb-1 mb-md-2">Email</p>
                      </div>
                      <h6 class="text-left">{{customerEmail}}</h6>
                    </div>
                    <div class="sub-item mb-3 mb-md-4">
                      <p class="text-muted text-left mb-1 mb-md-2">Address</p>
                      <h6 class="text-left">{{customerAddress}}</h6>
                    </div>
                  </div>

                  <div class="col-md-6 col-sm-12">
                    <div class="sub-item mb-3 mb-md-4">
                      <p class="text-muted text-left mb-1 mb-md-2">Last Name</p>
                      <h6 class="text-left">{{customerLastName}}</h6>
                    </div>
                    <div class="sub-item mb-3 mb-md-4">
                      <p class="text-muted text-left mb-1 mb-md-2">Mobile Number</p>
                      <h6 class="text-left">{{customerMobile}}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="isProfile" class="profile-detail mb-5">
              <!-- CHANGE PASSWORD -->
              <div class="profile-head ml-md-0 mr-md-0 mt-5 mb-1">
                <div class="row">
                  <div class="col-6 col-sm-6">
                    <h5 class="card-title text-left">CHANGE PASSWORD</h5>
                  </div>
                  <div class="col-6 col-sm-6">
                    <a *ngIf="isApproved == '2'" href="#changePassword" class="btn btn-primary float-right disabled"
                       data-toggle="modal"
                       (click)="sendOtpResetPassword()"><i
                      class="fas fa-edit mr-2"></i>Change</a>
                    <a *ngIf="isApproved == '0' || isApproved == '1'" href="#changePassword"
                       class="btn btn-primary float-right" data-toggle="modal"
                       (click)="sendOtpResetPassword()"><i
                      class="fas fa-edit mr-2"></i>CHANGE</a>
                  </div>
                </div>
              </div>
              <hr class="mt-1 mb-4">
            </div>

            <!-- ORDERS DETAIL -->
            <div *ngIf="isOrders" class="profile-detail">
              <div class="profile-head ml-md-0 mr-md-0 mt-5 mb-3">
                <h5 class="card-title text-left">MY ORDERS</h5>
              </div>
              <hr class="mt-1 mb-4">

              <div class="search-box">
                <div class="row">
                  <div class="col-12 col-sm-5 col-md-5">
                    <!--                    <form class="form-inline mb-2">-->
                    <!--                      <div class="input-group">-->
                    <!--                        <input type="search" class="form-control" placeholder="Search" aria-label="Search"-->
                    <!--                               aria-describedby="basic-addon02" [(ngModel)]="searchText" autocomplete="off"-->
                    <!--                               [ngModelOptions]="{standalone: true}">-->
                    <!--                        <div class="input-group-append">-->
                    <!--                          <span class="input-group-text" id="basic-addon02"><i class="fas fa-search"></i></span>-->
                    <!--                        </div>-->
                    <!--                      </div>-->
                    <!--                    </form>-->
                  </div>
                  <div class="col-12 col-sm-5 col-md-5 offset-sm-2">
                    <form class="form-inline text-center text-sm-right float-sm-right mb-2">
                      <div class="input-group">
                        <!--                        <input class="form-control date-filter" type="date" placeholder="Date" aria-label="Date"-->
                        <!--                               height="36px">-->
                        <input type="text"
                               placeholder="Select Date Range"
                               class="form-control"
                               id="orderDateRange"
                               [bsConfig]="{ containerClass:'theme-purple',
                               showWeekNumbers: false, isAnimated: true}"
                               (bsValueChange)="getOrdersByDateRange($event)"
                               bsDaterangepicker>
                        <div class="input-group-append">
                          <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="loading text-center font-weight-500 p-3" *ngIf="showTable == false">
                <span><i class="fa fa-spinner fa-spin mr-2"></i> Please wait...</span>
              </div>

              <div *ngIf="showTable == true">
                <div class="profile-detail-table  table-responsive-xl mt-3 mb-5" *ngIf="responseDataArray?.length != 0">
                  <table datatable [dtOptions]="dtOptions" class="table table-condensed dt-responsive"
                         style="width: 100%!important">
                    <thead class="p-2">
                    <tr>
                      <td>
                        <div class="row">
                          <div class="col-6 col-sm-3 col-md-2">
                            Order Ref
                          </div>
                          <div class="col-6 col-sm-3 col-md-3">
                            Order Date
                          </div>
                          <div class="col-6 col-sm-3 col-md-2">
                            Merchant
                          </div>
                          <div class="col-6 col-sm-3 col-md-2">
                            Action
                          </div>
                          <div class="col-6 col-sm-3 col-md-2">
                            Info
                          </div>
                        </div>
                      </td>
                    </tr>
                    </thead>

                    <tbody>
                    <tr *ngFor="let orders of responseDataArray">
                      <td>
                        <!-- FIRST ORDER -->
                        <div class="item-list">
                          <div class="space-tr">
                            <div class="row">
                              <div id="orderRefNumb" class="col-6 col-sm-3 col-md-2">
                                <input type="hidden" value="{{orders.orderReferenceNumber}}">
                                <div class="order-ref-number bg-light rounded p-2">
                                  <span>#{{orders.orderReferenceNumber}}</span></div>
                              </div>
                              <div class="col-6 col-md-3">
                                <div class="mt-1">{{orders.ordered_date}}</div>
                              </div>
                              <div class="col-6 col-md-2">
                                <div class="mt-1">{{orders.merchant_name}}</div>
                              </div>
                              <div class="col-6 col-sm-3 col-md-2 mt-2 mt-md-0" *ngIf="orders.order_status != 1">
                                <div class="mt-1">
                                  <button class="btn btn-primary btn-sm mt-0"
                                          [attr.disabled]="isApproved == '2'? true : null"
                                          (click)="openConfirmation(confirmationDialog, orders)">PAY NOW
                                  </button>
                                </div>
                              </div>
                              <div class="col-6 col-sm-3 col-md-2 mt-2 mt-md-0" *ngIf="orders.order_status == 1">
                                <div class="complete-txt text-success mt-1">Completed</div>
                              </div>
                              <div class="col-6 col-sm-3 col-sm-6 col-md-3 mt-2 mt-md-0">
                                <div class="mt-1"><a href="#showDetail-{{orders.orderReferenceNumber}}" (click)="collapsed($event.target,orders.orderReferenceNumber)"
                                                     data-toggle="collapse" class="accordion-toggle">Show Details</a>

                                </div>
                              </div>
                            </div>
                          </div>


                            <div class="space-tr">
                              <div class="hiddenRow">
                                <div #readMore class="accordian-body collapse" id="showDetail-{{orders.orderReferenceNumber}}">
                                  <div class="show-detail shadow-lg pb-1 mt-3 mb-3 bg-white rounded">
                                    <div class="nav-list">
                                      <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li class="nav-item waves-effect waves-light text-center w-50">
                                          <a class="nav-link order-det active" id="order-tab" data-toggle="tab"
                                             href="#order-{{orders.order_id}}" role="tab" aria-controls="order"
                                             aria-selected="true">Order Details</a>
                                        </li>
                                        <li class="nav-item waves-effect waves-light text-center w-50">
                                          <a class="nav-link order-det" id="instal-tab" data-toggle="tab"
                                             href="#installment-{{orders.order_id}}" role="tab" aria-controls="installment"
                                             aria-selected="false">Instalment Details</a>
                                        </li>
                                      </ul>
                                    </div>

                                  <div class="card shadow-md-sm border-0">
                                    <div class="tab-content p-3" id="myTabContent">

                                      <!-- ORDER DETAILS TAB SECTION -->
                                      <div class="tab-pane fade active show" id="order-{{orders.order_id}}"
                                           role="tabpanel"
                                           aria-labelledby="order-tab">
                                        <div class="row">
                                          <div class="col-6 col-sm-6 col-md-4 order-1 order-md-1">
                                            <div class="detail-box mt-3 mb-2">
                                              <p class="d-title">Merchant Name</p>
                                              <h6 class="d-subtitle">{{orders.merchant_name}}</h6>
                                            </div>
                                          </div>
                                          <div class="col-6 col-sm-6 col-md-4 order-3 order-md-2">
                                            <div class="detail-box mt-3 mb-2">
                                              <p class="d-title">Total Instalments</p>
                                              <h6 class="d-subtitle">{{orders.total_installments}}</h6>
                                            </div>
                                          </div>
                                          <div class="col-6 col-sm-6 col-md-4 order-2 order-md-3">
                                            <div class="detail-box mt-3 mb-2">
                                              <p class="d-title">Order Amount</p>
                                              <!--                                        <h6 class="d-subtitle">LKR 8,000</h6>-->
                                              <h6 class="d-subtitle">LKR {{orders.order_amount}}</h6>
                                            </div>
                                          </div>
                                          <div class="col-6 col-sm-6 col-md-4 order-4 order-md-4 d-block d-sm-none">
                                            <div class="detail-box mt-3 mb-2">
                                              <p class="d-title">Order Date</p>
                                              <!--                                        <h6 class="d-subtitle">01/02/2020</h6>-->
                                              <h6 class="d-subtitle">{{orders.ordered_date | date : "dd.MM.y"}}</h6>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="row">
                                          <div class="col-6 col-sm-6 col-md-4 order-3 order-md-1">
                                            <div class="detail-box mt-3 mb-2">
                                              <p class="d-title">Status</p>
                                              <!-- If Status Success -->
                                              <span *ngIf="orders.order_status == '1'"
                                                    class="badge badge-pill badge-success px-3 mt-0">Completed</span>

                                              <!-- If Status In Progress -->
                                              <span *ngIf="orders.order_status == '4'"
                                                    class="badge badge-pill badge-warning px-3 mt-0">In Progress</span>

                                              <!-- If Status Failed -->
                                              <span *ngIf="orders.order_status == '2'"
                                                    class="badge badge-pill badge-danger px-3 mt-0">Failed</span>

                                              <!-- If Status Cancelled -->
                                              <span *ngIf="orders.order_status == '3'"
                                                    class="badge badge-pill badge-danger px-3 mt-0">Cancelled</span>

                                            </div>
                                          </div>
                                          <div class="col-6 col-sm-6 col-md-4 order-1 order-md-2">
                                            <div class="detail-box mt-3 mb-2">
                                              <p class="d-title">Remaining Instalments</p>
                                              <h6 class="d-subtitle">{{orders.remaining_installments}}</h6>
                                            </div>
                                          </div>
                                          <div class="col-6 col-sm-6 col-md-4 order-2 order-md-3">
                                            <div class="detail-box mt-3 mb-2">
                                              <p class="d-title">Outstanding Amount</p>
                                              <h6 class="d-subtitle">LKR {{orders.outstanding_amount}}</h6>
                                            </div>
                                          </div>
                                        </div>

                                        <div class="row">
                                          <div class="col-6 col-sm-6 col-md-4 order-3 order-md-1">
                                          </div>
                                          <div class="col-6 col-sm-6 col-md-4 order-1 order-md-2">
                                          </div>
                                          <div class="col-6 col-sm-6 col-md-4 order-2 order-md-3">
                                            <div class="detail-box mt-3 mb-2">
                                              <p class="d-title">Paid Amount</p>
                                              <h6 class="d-subtitle">LKR {{orders.paid_amount}}</h6>
                                            </div>
                                          </div>
                                        </div>

                                      </div>

                                      <!-- INSTALLMENT DETAILS TAB SECTION -->
                                      <div class="tab-pane fade" id="installment-{{orders.order_id}}" role="tabpanel"
                                           aria-labelledby="installment-tab">
                                        <hr class="mt-4">

                                        <!-- INSTALLMENT BELOW SECTION -->
                                        <div class="installment-box"
                                             *ngFor="let orderInst of orderInstallmentArray[orders.order_id]">
                                          <!-- INSTALLMENT LIST ONE-->

                                          <div class="instal-list accordion-toggle collapsed pb-3 mb-2"
                                               id="#{{orders.order_id}}" data-toggle="collapse" data-parent="#accordion"
                                               href="#collapseOne">

                                            <div class="row">
                                              <div class="col-12 col-sm-12 col-xl-2">
                                                <div class="expand-button mt-3">
                                                  <span>{{orderInst.installment_level}}</span>
                                                  <div
                                                    *ngIf="latePaymentArray[orders.order_id][orderInst.installment_level] != 0">
                                                    <a data-toggle="collapse" style="padding-left:5em; padding-top:2em;"
                                                       href="#collapseOne-{{orderInst.installment_level}}" role="button"
                                                       aria-expanded="false" aria-controls="collapseExample">
                                                      <i class="fas fa-plus"></i>
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="col-6 col-sm-6 col-xl-3">
                                                <div class="detail-box mt-3 mb-2">
                                                  <p class="d-title">Instalment Date</p>
                                                  <h6 class="d-subtitle">{{orderInst.due_date}}</h6>
                                                </div>
                                              </div>
                                              <div class="col-6 col-sm-6 col-xl-2">
                                                <div class="detail-box mt-3 mb-2">
                                                  <p class="d-title">Amount</p>
                                                  <h6 class="d-subtitle">LKR {{orderInst.payable_amount}}</h6>
                                                </div>
                                              </div>
                                              <div class="col-6 col-sm-6 col-xl-3">
                                                <div class="detail-box mt-3 mb-2">
                                                  <p class="d-title">Paid Date</p>
                                                  <h6 class="d-subtitle"
                                                      *ngIf="orderInst.paidDate">{{orderInst.paidDate}}</h6>
                                                  <h6 class="d-subtitle font-italic" *ngIf="!orderInst.paidDate">
                                                    Awaiting payment</h6>
                                                </div>
                                              </div>
                                              <div class="col-6 col-sm-6 col-xl-2">
                                                <div class="detail-box mt-3 mb-2"
                                                     *ngIf="orderInst.order_installments_status == 0">
                                                  <p class="d-title">Status</p>
                                                  <h6 class="d-subtitle ml-1">
                                                    <i class="fas fa-hourglass-half failed-status fa-lg"></i>
                                                  </h6>
                                                </div>
                                                <div class="detail-box mt-3 mb-2"
                                                     *ngIf="orderInst.order_installments_status != 0">
                                                  <p class="d-title">Status</p>
                                                  <h6 class="d-subtitle ml-1">
                                                    <i class="fas fa-check-circle success-status fa-lg"></i>
                                                  </h6>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div class="hide-table-padding"
                                               *ngIf="latePaymentArray[orders.order_id][orderInst.installment_level] != 0">
                                            <div id="collapseOne-{{orderInst.installment_level}}"
                                                 class="collapse in mb-5"
                                                 *ngFor="let instLatePayements of latePaymentArray[orders.order_id][orderInst.installment_level]">
                                              <div class="row justify-content-md-end">
                                                <div class="col-6 col-sm-6 col-xl-3">
                                                  <div class="detail-box mt-3 mb-2">
                                                    <p class="d-title">Instalment Date</p>
                                                    <h6 class="d-subtitle">{{instLatePayements.due_date}}</h6>
                                                  </div>
                                                </div>
                                                <div class="col-6 col-sm-6 col-xl-2">
                                                  <div class="detail-box mt-3 mb-2"
                                                       *ngIf="instLatePayements.penalty_id == 1">
                                                    <p class="d-title">Amount</p>
                                                    <h6 class="d-subtitle">LKR {{instLatePayements.payable_amount}}
                                                      <span class="icon-info ml-1" placement="top"
                                                            ngbTooltip="Late Payment Fee">
                                                        <i class="fas fa-info-circle fa-lg"></i>
                                                      </span>
                                                    </h6>
                                                  </div>
                                                  <div class="detail-box mt-3 mb-2"
                                                       *ngIf="instLatePayements.penalty_id == 2">
                                                    <p class="d-title">Amount</p>
                                                    <h6 class="d-subtitle">LKR {{instLatePayements.payable_amount}}
                                                      <span class="icon-info ml-1" placement="top"
                                                            ngbTooltip="Additional Fee">
                                                        <i class="fas fa-info-circle fa-lg"></i>
                                                      </span>
                                                    </h6>
                                                  </div>
                                                </div>
                                                <div class="col-6 col-sm-6 col-xl-3">
                                                  <div class="detail-box mt-3 mb-2">
                                                    <p class="d-title">Paid Date</p>
                                                    <h6 class="d-subtitle">{{orderInst.paidDate}}</h6>
                                                  </div>
                                                </div>
                                                <div class="col-6 col-sm-6 col-xl-2">
                                                  <div class="detail-box mt-3 mb-2"
                                                       *ngIf="orderInst.order_Instalments_status == 0">
                                                    <p class="d-title">Status</p>
                                                    <h6 class="d-subtitle ml-1">
                                                      <span class="failed-status">
                                                        <i class="fas fa-times-circle fa-lg"></i>
                                                      </span>
                                                    </h6>
                                                  </div>
                                                  <div class="detail-box mt-3 mb-2"
                                                       *ngIf="orderInst.order_Instalments_status != 0">
                                                    <p class="d-title">Status</p>
                                                    <h6 class="d-subtitle ml-1">
                                                      <i class="fas fa-check-circle success-status fa-lg"></i>
                                                    </h6>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="profile-detail-table mt-4 mb-5" *ngIf="responseDataArray?.length == 0">
                <h5 class="text-muted">No records found!</h5>
              </div>

              <!--              <div class="row">-->
              <!--                <div class="col-1 col-sm-1 col-md-1">-->
              <!--                </div>-->
              <!--                <div class="col-11 col-sm-11 col-md-11 d-flex justify-content-end">-->
              <!--                  <ngb-pagination [collectionSize]="collectionSize" #numPages [pageSize]="itemsPerPage" [(page)]="currentPage"  [boundaryLinks]="true" (pageChange)="onPageChange(currentPage)">-->
              <!--                    <ng-template ngbPaginationFirst>First</ng-template>-->
              <!--                    <ng-template ngbPaginationPrevious>Prev</ng-template>-->
              <!--                    <ng-template ngbPaginationNext>Next</ng-template>-->
              <!--                    <ng-template ngbPaginationLast>Last</ng-template>-->
              <!--                  </ngb-pagination>-->
              <!--                </div>-->
              <!--              </div>-->
            </div>

            <!-- MY CARD DETAIL -->
            <div *ngIf="isMyCards" class="profile-detail">
              <div class="profile-head ml-md-0 mr-md-0 mt-5 mb-2">
                <div class="row">
                  <div class="col-6 col-sm-6">
                    <h5 class="card-title text-left mb-3 mb-sm-0">MY CARDS</h5>
                  </div>
                  <div class="col-6 col-sm-6">
                    <button class="btn btn-primary float-right" [attr.disabled]="isApproved == '2'? true : null"
                            (click)="addCardClick($event.target)"><i
                      class="fas fa-plus fa-lg mr-2"></i> ADD NEW CARD
                    </button>
                  </div>
                </div>
              </div>
              <hr class="mt-1 mb-4">
              <div class="profile-detail-table mt-4 mb-5">
                <div class="table table-condensed" style="border-collapse:collapse;">
                  <!-- List of cards -->
                  <div class="thead">
                    <div class="row">
                      <div class="col-6 col-md-2 text-left">
                        Card Type
                      </div>
                      <div class="col-6 col-md-3 text-left">
                        Card Number
                      </div>
                      <div class="col-6 col-md-2 text-left">
                        Default
                      </div>
                      <div class="col-6 col-md-2 text-left">
                        Expiry date
                      </div>
                      <div class="col-6 col-md-3 text-left">
                        Info
                      </div>
                    </div>
                  </div>
                  <div class="item-list" *ngFor="let card of myCards; let index = index">
                    <div class="tr space-tr">
                      <div class="row">
                        <div class="col-6 col-md-2 pr-0">
                          <div class="td bg-light rounded p-2"><span>{{card.type}}</span></div>
                        </div>
                        <div class="col-6 col-md-3">
                          <div class="td mt-1">{{card.maskNo}}</div>
                        </div>
                        <div class="col-6 col-md-2 mt-2 mt-md-0">
                          <div class="td mt-1">
                              <i *ngIf="card.default == 1;else not_default"
                                 class="fas fa-check-circle text-primary fa-lg"></i>
                              <ng-template #not_default>
                                <!--                                            <i class="fas fa-times-circle fa-lg"></i>-->
                                <a href="#setDefaultCard{{card.cardCode}}" class="btn btn-outline-primary btn-sm" data-toggle="modal">Set
                                  as default</a>
                              </ng-template>
                          </div>
                        </div>
                        <div class="col-6 col-md-2">
                          <div class="td mt-1">{{card.expire_date}}</div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-3 mt-2 mt-md-0">
                          <div class="td mt-1"><a href="#cardInfoTab{{card.cardCode}}" data-toggle="collapse"
                                                  class="accordion-toggle"
                                                  (click)="updateDefaultCardConfirm($event.target, card.cardCode)">Show Details</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tr space-tr">
                      <div class="td hiddenRow">
                        <div #readMore class="accordian-body collapse" id="cardInfoTab{{card.cardCode}}">
                          <div class="show-detail shadow-lg pb-1 mt-3 mb-3 bg-white rounded">

                            <div class="nav-list">
                              <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item waves-effect waves-light text-center w-100">
                                  <a class="nav-link active" id="myCard-tab" data-toggle="tab" href="#myCard" role="tab"
                                     aria-controls="myCard" aria-selected="true">Card Details</a>
                                </li>
                              </ul>
                            </div>

                            <div class="card shadow-md-sm border-0">
                              <div class="tab-content p-3">

                                <!-- MY CARD DETAILS TAB SECTION -->
                                <div class="tab-pane fade active show" id="myCard" role="tabpanel"
                                     aria-labelledby="myCard-tab">
                                  <div class="row justify-content-end">
                                    <div class="col-6">
                                      <div class="detail-box mt-3 mb-2">
                                        <p class="d-title">Issuer</p>
                                        <h6 class="d-subtitle">{{card.bank_name}}</h6>
                                      </div>
                                    </div>
                                    <div class="col-6">
                                      <div class="detail-box mt-3 mb-2">
                                        <p class="d-title">Associated Instalments</p>
                                        <h6 class="d-subtitle">{{card.orderCount}}</h6>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-7">
                                      <button data-target="#removeCard"
                                              [attr.disabled]="card.orderCount !== 0 || isApproved == '2'? true : null"
                                              (click)="removeCardConfirm(card.cardCode, card.orderCount)"
                                              class="btn btn-outline-primary btn-sm mt-3"
                                              data-toggle="modal">REMOVE CARD
                                      </button>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="profile-detail-table mt-4 mb-5" *ngIf="myCards?.length == 0">
                <h5 class="text-muted">No records found!</h5>
              </div>
            </div>


            <!-- MODALS -->

            <!-- PAY NOW CONFIRMATION MODAL START -->
            <ng-template #confirmationDialog let-c="close" let-d="dismiss">
              <div class="modal-header paynow--modal-head">
                <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body text-center paynow--modal-body">
                <h4 class="modal-title" id="modal-basic-title">Confirm Action</h4>
                <p class="mx-4 my-4">By proceeding you will pay your next instalment.</p>
                <p class="mx-4">
                  <span>Order Reference Number :</span> {{ modalContent.orderReferenceNumber }}</p>
                <p class="mx-4">
                  <span>Instalment Number :</span> {{ modalContent.next_installment_level_topay }}</p>
                <p class="mx-4">
                  <span>Instalment Amount : </span> LKR {{ modalContent.next_installment_amount_topay }}</p>
              </div>
              <div class="modal-footer paynow--modal-footer justify-content-center">
                <button type="button" class="btn btn-secondary" (click)="d('Cross click')">Cancel</button>
                <button type="button" class="btn btn-outline-primary"
                        (click)="payNowClick($event.target,modalContent.orderReferenceNumber)">Pay Now
                </button>
              </div>
            </ng-template>
            <!-- PAY NOW CONFIRMATION MODAL END -->

            <!-- REMOVE CARD MODAL -->
            <div id="removeCard" class="modal fade">
              <div class="modal-dialog modal-confirm">
                <div class="modal-content">
                  <div class="modal-header flex-column">
                    <div class="icon-box-danger">
                      <i class="material-icons">&#xE5CD;</i>
                    </div>
                    <h5 class="modal-title w-100">Confirm Action</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                  </div>
                  <div class="modal-body">
                    <p class="mr-4 ml-4">Do you really want to remove this card? This process cannot be undone.</p>
                  </div>
                  <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">CANCEL</button>
                    <button type="button" class="btn btn-danger" (click)="confirmRemoveCard()" data-dismiss="modal">
                      REMOVE
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- VERIFY EMAIL MODAL -->
            <div id="verifyEmail" class="modal fade">
              <div class="modal-dialog modal-confirm">

                <!-- EMAIL VERIFY STEP 1 -->
                <div *ngIf="isEmailVerifyStep1" class="modal-content">
                  <div class="modal-header flex-column">
                    <h5 class="modal-title w-100">Email Verification</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                  </div>
                  <div class="modal-body">
                    <form class="ml-md-5 mr-md-5 mb-2" [formGroup]="otpForm">
                      <div class="form-group">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon5"><i class="fas fa-unlock-alt"></i></span>
                          </div>
                          <input type="text" class="form-control" placeholder="OTP" aria-label="otpEmail"
                                 [(ngModel)]="otpEmail"
                                 aria-describedby="basic-addon5" formControlName="otpEmail"
                                 [ngClass]="{'is-invalid': otpForm.get('otpEmail').touched && otpForm.get('otpEmail').invalid}">

                          <div *ngIf="otpForm.get('otpEmail').touched && otpForm.get('otpEmail').invalid"
                               class="invalid-feedback text-right">
                            <div *ngIf="otpForm.get('otpEmail').errors.required">This field is required.</div>
                            <div *ngIf="otpForm.get('otpEmail').errors.pattern">OTP must be at least 6 digits</div>
                          </div>
                        </div>
                      </div>

                      <p *ngIf="showResendOtp == true" class="card-text forget-pwd text-right ml-2"><a [routerLink]="" (click)="reSendOtp()"><small
                        class="text-muted">Re-send OTP</small></a></p>
<!--                      <p class="card-text forget-pwd text-left ml-2"><a [routerLink]="" (click)="reSendOtp()"><small-->
<!--                        class="text-muted">Re-send OTP</small></a></p>-->
                      <button type="submit" class="btn btn-primary w-100 p-2 mt-1 mb-3" [disabled]="!otpForm.valid"
                              (click)="verifySuccess($event.target)">SUBMIT
                      </button>
                    </form>
                  </div>
                </div>

                <!-- EMAIL VERIFY STEP 2 -->
                <div *ngIf="isEmailVerifyStep2" class="modal-content">
                  <div class="modal-header justify-content-center flex-column">
                    <div class="icon-box-success">
                      <i class="material-icons">&#xE876;</i>
                    </div>
                    <h5 class="modal-title w-100">Amazing!</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                  </div>
                  <div class="modal-body">
                    <p>Your email has been verified successfully.</p>
                  </div>
                  <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-primary" data-dismiss="modal">EXPLORE</button>
                  </div>
                </div>
              </div>
            </div>

            <!-- EDIT REJECTED PROFILE MODAL -->
            <div id="rejectedProfileEdit" class="modal fade">
              <div class="modal-dialog modal-confirm">

                <!-- EDIT PROFILE STEP 1 -->
                <div *ngIf="isEditStep1" class="modal-content">
                  <div class="modal-header flex-column">
                    <h5 class="modal-title w-100">Update Profile</h5>
                    <button type="button" #closebutton1 class="close" data-dismiss="modal" aria-hidden="true">&times;
                    </button>
                  </div>
                  <div class="modal-body">
                    <form class="ml-md-5 mr-md-5 mb-2" [formGroup]="editRejectedProfileForm">
                      <div id="fName" class="form-group rejectData">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-user"></i></span>
                          </div>
                          <input type="text" class="form-control" placeholder="First Name" aria-label="First Name"
                                 [(ngModel)]="rejectedFirstName"
                                 aria-describedby="basic-addon6" formControlName="rejectedFirstName"
                                 id="rejectedFirstName"
                                 [ngClass]="{'is-invalid': editRejectedProfileForm.get('rejectedFirstName').touched && editRejectedProfileForm.get('rejectedFirstName').invalid}">
                          <div
                            *ngIf="editRejectedProfileForm.get('rejectedFirstName').touched && editRejectedProfileForm.get('rejectedFirstName').invalid"
                            class="invalid-feedback text-right">
                            <div *ngIf="editRejectedProfileForm.get('rejectedFirstName').errors.required">This field is
                              required.
                            </div>
                            <div *ngIf="editRejectedProfileForm.get('rejectedFirstName').errors.minlength">First Name
                              must be at least 3
                              characters
                            </div>
                          </div>
                        </div>
                      </div>

                      <div id="lName" class="form-group rejectData">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-user"></i></span>
                          </div>
                          <input type="text" class="form-control" placeholder="Last Name" aria-label="Last Name"
                                 [(ngModel)]="rejectedLastName" aria-describedby="basic-addon7"
                                 formControlName="rejectedLastName"
                                 id="rejectedLastName"
                                 [ngClass]="{'is-invalid': editRejectedProfileForm.get('rejectedLastName').touched && editRejectedProfileForm.get('rejectedLastName').invalid}">
                          <div
                            *ngIf="editRejectedProfileForm.get('rejectedLastName').touched && editRejectedProfileForm.get('rejectedLastName').invalid"
                            class="invalid-feedback text-right">
                            <div *ngIf="editRejectedProfileForm.get('rejectedLastName').errors.required">This field is
                              required.
                            </div>
                            <div *ngIf="editRejectedProfileForm.get('rejectedLastName').errors.minlength">Last Name must
                              be at least 3
                              characters
                            </div>
                          </div>
                        </div>
                      </div>

                      <div id="mobile" class="form-group rejectData">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon2"><i class="fas fa-phone" aria-hidden="true"></i><span class="pl-3">+94</span></span>
                          </div>
                          <input type="text" class="form-control" placeholder="Mobile" aria-label="Mobile"
                                 [(ngModel)]="rejectedPhone"
                                 aria-describedby="basic-addon8" formControlName="rejectedPhone" id="rejectedPhone"
                                 [ngClass]="{'is-invalid': editRejectedProfileForm.get('rejectedPhone').touched && editRejectedProfileForm.get('rejectedPhone').invalid}">
                          <div
                            *ngIf="editRejectedProfileForm.get('rejectedPhone').touched && editRejectedProfileForm.get('rejectedPhone').invalid"
                            class="invalid-feedback text-right">
                            <div *ngIf="editRejectedProfileForm.get('rejectedPhone').errors.required">This field is
                              required.
                            </div>
                            <div *ngIf="editRejectedProfileForm.get('rejectedPhone').errors.pattern">Please enter a
                              valid mobile
                              number.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div id="rAddress" class="form-group rejectData">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                              <span class="input-group-text"><i
                                class="fas fa-map-marker-alt"></i></span>
                          </div>
                          <input type="text" class="form-control" placeholder="Address" aria-label="Address"
                                 [(ngModel)]="rejectedAddress"
                                 aria-describedby="basic-addon9" formControlName="rejectedAddress" id="rejectedAddress"
                                 [ngClass]="{'is-invalid': editRejectedProfileForm.get('rejectedAddress').touched && editRejectedProfileForm.get('rejectedAddress').invalid}">
                          <div
                            *ngIf="editRejectedProfileForm.get('rejectedAddress').touched && editRejectedProfileForm.get('rejectedAddress').invalid"
                            class="invalid-feedback text-right">
                            <div *ngIf="editRejectedProfileForm.get('rejectedAddress').errors.required">This field is
                              required.
                            </div>
                            <div *ngIf="editRejectedProfileForm.get('rejectedAddress').errors.minlength">Address must be
                              at least 3
                              characters
                            </div>
                          </div>
                        </div>
                      </div>

                      <div id="rEmail" class="form-group rejectData">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-envelope"></i></span>
                          </div>
                          <input type="email" class="form-control" placeholder="Email" aria-label="Email"
                                 [(ngModel)]="rejectedEmail"
                                 formControlName="rejectedEmail"
                                 [ngClass]="{'is-invalid': editRejectedProfileForm.get('rejectedEmail').touched && editRejectedProfileForm.get('rejectedEmail').invalid}">
                          <div
                            *ngIf="editRejectedProfileForm.get('rejectedEmail').touched && editRejectedProfileForm.get('rejectedEmail').invalid"
                            class="invalid-feedback text-right">
                            <div *ngIf="editRejectedProfileForm.get('rejectedEmail').errors.required">This field is
                              required.
                            </div>
                            <div *ngIf="editRejectedProfileForm.get('rejectedEmail').errors.pattern">Please enter a
                              valid email address.
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- NIC/Passport or Driving Licence -->
                      <div id="rNic" class="rejectData">
                        <div class="form-group">
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <span class="input-group-text"><i class="fas fa-address-card"></i></span>
                            </div>
                            <input type="hidden" [(ngModel)]="radioValue" formControlName="radioValue" hidden>
                            <input type="hidden" [(ngModel)]="documentName" formControlName="documentName" hidden>
                            <input type="hidden" [(ngModel)]="documentBackName" formControlName="documentBackName"
                                   hidden>
                            <input type="text" class="form-control" placeholder="NIC" aria-label="nic"
                                   [(ngModel)]="nicNum"
                                   aria-describedby="basic-addon9" formControlName="nicNum"
                                   [ngClass]="{'is-invalid': editRejectedProfileForm.get('nicNum').touched && editRejectedProfileForm.get('nicNum').invalid}">
                            <div
                              *ngIf="editRejectedProfileForm.get('nicNum').touched && editRejectedProfileForm.get('nicNum').invalid"
                              class="invalid-feedback text-right">
                              <div *ngIf="editRejectedProfileForm.get('nicNum').errors.required">This field is
                                required.
                              </div>
                              <div *ngIf="editRejectedProfileForm.get('nicNum').errors.pattern">Enter a valid NIC</div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <div id="imageOne" class="form-group rejectData">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-file-upload"></i></span>
                          </div>
                          <div class="custom-file">
                            <!--                    <input type="file" class="custom-file-input" id="customFile" formControlName="fileUpload" [(ngModel)]="nicFile">-->
                            <input type="file" class="custom-file-input" id="file" formControlName="imageOneUpload"
                                   (change)="handleFileInput($event.target.files)"
                                   accept="image/png, image/jpg, image/jpeg, image/pdf">
                            <label class="custom-file-label text-left" *ngIf="imageName">{{imageName}}</label>
                            <label class="custom-file-label text-left" *ngIf="!imageName">Upload Image One</label>
                          </div>
                          <div *ngIf="imageSize > 3" style="color: red">File size exceed the maximum file size.</div>
                        </div>
                      </div>

                      <div id="imageTwo" class="form-group rejectData">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon20"><i class="fas fa-file-upload"></i></span>
                          </div>
                          <div class="custom-file">
                            <!--                    <input type="file" class="custom-file-input" id="customFile" formControlName="fileUpload" [(ngModel)]="nicFile">-->
                            <input type="file" class="custom-file-input" id="file1" formControlName="imageTwoUpload"
                                   (change)="handleFileInput1($event.target.files)"
                                   accept="image/png, image/jpg, image/jpeg, image/pdf">
                            <label class="custom-file-label text-left" *ngIf="imageName1">{{imageName1}}</label>
                            <label class="custom-file-label text-left" *ngIf="!imageName1">UUpload Image Two</label>
                          </div>
                          <div *ngIf="imageSize > 3" style="color: red">File size exceed the maximum file size.</div>
                        </div>
                      </div>


                      <button type="submit" class="btn btn-primary w-100 p-2 mt-1 mb-3"
                              [disabled]="!editRejectedProfileForm.valid"
                              (click)="editRejectedProfileStep1($event.target)">NEXT
                      </button>
                    </form>
                  </div>
                </div>

                <!-- EDIT PROFILE STEP 2 -->
                <div *ngIf="isEditStep2" class="modal-content">
                  <div class="modal-header flex-column">
                    <h5 class="modal-title w-100">Verify Phone Number</h5>
                    <button type="button" #closebutton1 class="close" data-dismiss="modal" aria-hidden="true">&times;
                    </button>
                  </div>
                  <div class="modal-body">
                    <form class="ml-md-5 mr-md-5 mb-2" [formGroup]="editProfileOtpForm">
                      <div class="form-group">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-unlock-alt"></i></span>
                          </div>

                          <!-- <input type="text" class="form-control" placeholder="OTP" aria-label="otpEmail" [(ngModel)]="otpEmail"
                            aria-describedby="basic-addon5" formControlName="otpEmail"
                            [ngClass]="{'is-invalid': otpForm.get('otpEmail').touched && otpForm.get('otpEmail').invalid}">

                          <div *ngIf="otpForm.get('otpEmail').touched && otpForm.get('otpEmail').invalid" class="invalid-feedback text-right">
                            <div *ngIf="otpForm.get('otpEmail').errors.required">This field is required.</div>
                            <div *ngIf="otpForm.get('otpEmail').errors.pattern">OTP must be at least 6 digits</div> -->

                          <input type="text" class="form-control" placeholder="OTP" aria-label="otp" [(ngModel)]="otp"
                                 aria-describedby="basic-addon10" formControlName="otp"
                                 [ngClass]="{'is-invalid': editProfileOtpForm.get('otp').touched && editProfileOtpForm.get('otp').invalid}">
                          <div *ngIf="editProfileOtpForm.get('otp').touched && editProfileOtpForm.get('otp').invalid"
                               class="invalid-feedback text-right">
                            <div *ngIf="editProfileOtpForm.get('otp').errors.required">This field is required.</div>
                            <div *ngIf="editProfileOtpForm.get('otp').errors.pattern">please enter 6 digits.
                            </div>

                          </div>
                        </div>
                      </div>
                      <p *ngIf="showResendOtp == true" class="card-text forget-pwd text-right ml-2">
                        <a [routerLink]="" (click)="reSendOtpForContactNumber()"><small class="text-muted">Re-send
                          OTP</small></a></p>
                      <button type="submit" class="btn btn-primary w-100 p-2 mt-1"
                              [disabled]="!editProfileOtpForm.valid" (click)="editProfileStep2($event.target)">SUBMIT
                      </button>
                      <p class="card-text mt-3  mb-3"><small><a routerLink="#"
                                                                (click)="prevStep1($event.target)"><strong><i
                        class="fas fa-angle-double-left"></i> BACK</strong></a></small></p>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <!-- EDIT PROFILE EDIT -->
            <div id="editProfile" class="modal fade">
              <div class="modal-dialog modal-confirm">

                <!-- EDIT PROFILE STEP 1 -->
                <div *ngIf="isEditStep1" class="modal-content">
                  <div class="modal-header flex-column">
                    <h5 class="modal-title w-100">Update Profile</h5>
                    <button type="button" #closebutton1 class="close" data-dismiss="modal" aria-hidden="true">&times;
                    </button>
                  </div>
                  <div class="modal-body">
                    <form class="ml-md-5 mr-md-5 mb-2" [formGroup]="editProfileForm">
                      <div class="form-group">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon6"><i class="fas fa-user"></i></span>
                          </div>
                          <input type="text" class="form-control" placeholder="First Name" aria-label="First Name"
                                 [(ngModel)]="firstName"
                                 aria-describedby="basic-addon6" formControlName="firstName" id="firstName"
                                 [ngClass]="{'is-invalid': editProfileForm.get('firstName').touched && editProfileForm.get('firstName').invalid}">
                          <div
                            *ngIf="editProfileForm.get('firstName').touched && editProfileForm.get('firstName').invalid"
                            class="invalid-feedback text-right">
                            <div *ngIf="editProfileForm.get('firstName').errors.required">This field is required.</div>
                            <div *ngIf="editProfileForm.get('firstName').errors.minlength">First Name must be at least 3
                              characters
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon7"><i class="fas fa-user"></i></span>
                          </div>
                          <input type="text" class="form-control" placeholder="Last Name" aria-label="Last Name"
                                 [(ngModel)]="lastName" aria-describedby="basic-addon7" formControlName="lastName"
                                 id="lastName"
                                 [ngClass]="{'is-invalid': editProfileForm.get('lastName').touched && editProfileForm.get('lastName').invalid}">
                          <div
                            *ngIf="editProfileForm.get('lastName').touched && editProfileForm.get('lastName').invalid"
                            class="invalid-feedback text-right">
                            <div *ngIf="editProfileForm.get('lastName').errors.required">This field is required.</div>
                            <div *ngIf="editProfileForm.get('lastName').errors.minlength">Last Name must be at least 3
                              characters
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon2"><i class="fas fa-phone" aria-hidden="true"></i></span>
                          </div>
                          <input type="text" class="form-control" placeholder="Mobile Number" aria-label="Mobile"
                                 [(ngModel)]="phone"
                                 aria-describedby="basic-addon8" formControlName="phone" id="phone"
                                 [ngClass]="{'is-invalid': editProfileForm.get('phone').touched && editProfileForm.get('phone').invalid}">
                          <div *ngIf="editProfileForm.get('phone').touched && editProfileForm.get('phone').invalid"
                               class="invalid-feedback text-right">
                            <div *ngIf="editProfileForm.get('phone').errors.required">This field is required.</div>
                            <div *ngIf="editProfileForm.get('phone').errors.pattern">Please enter a valid mobile
                              number.
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon9"><i
                              class="fas fa-map-marker-alt"></i></span>
                          </div>
                          <input type="text" class="form-control" placeholder="Address" aria-label="Address"
                                 [(ngModel)]="address"
                                 aria-describedby="basic-addon9" formControlName="address" id="address"
                                 [ngClass]="{'is-invalid': editProfileForm.get('address').touched && editProfileForm.get('address').invalid}">
                          <div *ngIf="editProfileForm.get('address').touched && editProfileForm.get('address').invalid"
                               class="invalid-feedback text-right">
                            <div *ngIf="editProfileForm.get('address').errors.required">This field is required.</div>
                            <div *ngIf="editProfileForm.get('address').errors.minlength">Address must be at least 3
                              characters
                            </div>
                          </div>
                        </div>
                      </div>

                      <button type="submit" class="btn btn-primary w-100 p-2 mt-1 mb-3"
                              [disabled]="!editProfileForm.valid" (click)="editProfileStep1($event.target)">NEXT
                      </button>
                    </form>
                  </div>
                </div>

                <!-- EDIT PROFILE STEP 2 -->
                <div *ngIf="isEditStep2" class="modal-content">
                  <div class="modal-header flex-column">
                    <h5 class="modal-title w-100">Verify Phone Number</h5>
                    <button type="button" #closebutton1 class="close" data-dismiss="modal" aria-hidden="true">&times;
                    </button>
                  </div>
                  <div class="modal-body">
                    <form class="ml-md-5 mr-md-5 mb-2" [formGroup]="editProfileOtpForm">
                      <div class="form-group">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon10"><i class="fas fa-unlock-alt"></i></span>
                          </div>

                          <!-- <input type="text" class="form-control" placeholder="OTP" aria-label="otpEmail" [(ngModel)]="otpEmail"
                            aria-describedby="basic-addon5" formControlName="otpEmail"
                            [ngClass]="{'is-invalid': otpForm.get('otpEmail').touched && otpForm.get('otpEmail').invalid}">

                          <div *ngIf="otpForm.get('otpEmail').touched && otpForm.get('otpEmail').invalid" class="invalid-feedback text-right">
                            <div *ngIf="otpForm.get('otpEmail').errors.required">This field is required.</div>
                            <div *ngIf="otpForm.get('otpEmail').errors.pattern">OTP must be at least 6 digits</div> -->

                          <input type="text" class="form-control" placeholder="OTP" aria-label="otp" [(ngModel)]="otp"
                                 aria-describedby="basic-addon10" formControlName="otp"
                                 [ngClass]="{'is-invalid': editProfileOtpForm.get('otp').touched && editProfileOtpForm.get('otp').invalid}">

                          <div *ngIf="editProfileOtpForm.get('otp').touched && editProfileOtpForm.get('otp').invalid"
                               class="invalid-feedback text-right">
                            <div *ngIf="editProfileOtpForm.get('otp').errors.required">This field is required.</div>
                            <div *ngIf="editProfileOtpForm.get('otp').errors.pattern">please enter 6 digits
                            </div>

                          </div>
                        </div>
                      </div>


                      <p *ngIf="showResendOtp == true" class="card-text forget-pwd text-right ml-2">
                        <a [routerLink]="" (click)="reSendOtpForContactNumber()"><small
                        class="text-muted">Re-send OTP</small></a></p>
<!--                      <p class="card-text forget-pwd text-right ml-2"><a [routerLink]=""-->
<!--                                                                         (click)="reSendOtpForContactNumber()"><small-->
<!--                        class="text-muted">Re-send OTP</small></a></p>-->
                      <button type="submit" class="btn btn-primary w-100 p-2 mt-1"
                              [disabled]="!editProfileOtpForm.valid" (click)="editProfileStep2($event.target)">SUBMIT
                      </button>
                      <p class="card-text mt-3  mb-3"><small><a routerLink="#"
                                                                (click)="prevStep1($event.target)"><strong><i
                        class="fas fa-angle-double-left"></i> BACK</strong></a></small></p>
                    </form>
                  </div>
                </div>

              </div>
            </div>

            <!-- CHANGE PASSWORD MODAL -->
            <div id="changePassword" class="modal fade">
              <div class="modal-dialog modal-confirm">

                <!-- CHANGE PASSWORD STEP 1 -->
                <div class="modal-content">
                  <div class="modal-header flex-column">
                    <h5 class="modal-title w-100">Change Password</h5>
                    <button type="button" #closebutton2 class="close" data-dismiss="modal" aria-hidden="true">&times;
                    </button>
                  </div>
                  <div class="modal-body">

                    <form class="ml-md-5 mr-md-5 mb-2" [formGroup]="resetForm">
                      <div class="alert alert-info text-left mb-4" role="alert">
                        <i class="fas fa-info-circle mr-2"></i> An OTP has been sent to your email to reset your
                        password.
                      </div>
                      <div class="form-group">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                              <span class="input-group-text" id="basic-addon1"><i
                                class="fas fa-unlock-alt"></i></span>
                          </div>
                          <input type="text" class="form-control" placeholder="OTP" aria-label="otp"
                                 aria-describedby="basic-addon1" formControlName="otp" [(ngModel)]="OTPpassword"
                                 [ngClass]="{'is-invalid': resetForm.get('otp').touched && resetForm.get('otp').invalid}">
                          <div *ngIf="resetForm.get('otp').touched && resetForm.get('otp').invalid"
                               class="invalid-feedback text-right">
                            <div *ngIf="resetForm.get('otp').errors.required">This field is required.</div>
                            <div *ngIf="resetForm.get('otp').errors.pattern">please enter 6 digits.</div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon12"><i class="fas fa-lock"></i></span>
                          </div>
                          <input type="password" class="form-control" placeholder="New Password"
                                 aria-label="Password" [(ngModel)]="newPassword"
                                 aria-describedby="basic-addon2" formControlName="newPassword"
                                 [ngClass]="{'is-invalid': resetForm.get('newPassword').touched && resetForm.get('newPassword').invalid}">
                          <div *ngIf="resetForm.get('newPassword').touched && resetForm.get('newPassword').invalid"
                               class="invalid-feedback text-right">
                            <div *ngIf="resetForm.get('newPassword').errors.required">This field is required.</div>
                            <div *ngIf="resetForm.get('newPassword').errors.pattern">Password must be minimum 8
                              characters, at least 1 uppercase,
                              1 lowercase, 1 number & 1 special character
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon4"><i class="fas fa-lock"></i></span>
                          </div>
                          <input type="password" class="form-control" placeholder="Confirm Password"
                                 aria-label="ConfirmPassword"
                                 aria-describedby="basic-addon4" formControlName="confirmPassword"
                                 [(ngModel)]="confirmNewPassword"
                                 [ngClass]="{'is-invalid': resetForm.get('confirmPassword').touched && resetForm.get('confirmPassword').invalid}">
                          <div
                            *ngIf="resetForm.get('confirmPassword').touched && resetForm.get('confirmPassword').invalid"
                            class="invalid-feedback text-right">
                            <div *ngIf="resetForm.get('confirmPassword').errors.required">This field is required.
                            </div>
                            <div *ngIf="resetForm.get('confirmPassword').errors.passwordValidity">Confirm Password
                              does not match.
                            </div>
                          </div>
                        </div>
                      </div>

                      <p *ngIf="showResendOtp == true" class="card-text forget-pwd text-right ml-2"><a [routerLink]="" (click)="reSendOtp()"><small
                        class="text-muted">Re-send OTP</small></a></p>
                      <button type="submit" class="btn btn-primary w-100 p-2 mb-2 mt-1"
                              [disabled]="!resetForm.valid" (click)=changePassword($event.target)>CHANGE PASSWORD
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <!-- SET AS DEFAULT CARD MODAL -->
            <div  *ngFor="let card of myCards; let index = index">
            <div id="setDefaultCard{{card.cardCode}}" class="modal fade">
              <div class="modal-dialog modal-confirm">
                <div class="modal-content">
                  <div class="modal-header flex-column">
                    <div class="icon-box-warning">
                      <i class="material-icons">priority_high</i>
                    </div>
                    <h5 class="modal-title w-100">Confirm Action</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                  </div>
                  <div class="modal-body">
                    <p class="mr-4 ml-4">Do you really want to set as default this card?</p>
                  </div>
                  <div class="modal-footer justify-content-center">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">NO</button>
                    <button type="button" class="btn btn-primary" (click)="confirmDefaultCard(card.cardCode)" data-dismiss="modal">
                      YES
                    </button>
                  </div>
                </div>
              </div>
            </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>

