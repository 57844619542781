import {Component, OnInit, ViewChild, OnDestroy, ElementRef} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {SignUpService} from '../services/sign-up.service';
import {TokenService} from '../services/token.service';
import {LoginService} from '../services/login.service';
import {MyAccountService} from '../services/my-account.service';
import {CardService} from '../services/card.service';
import {NotifierService} from 'angular-notifier';
import {Idle, DEFAULT_INTERRUPTSOURCES} from '@ng-idle/core';
import {Keepalive} from '@ng-idle/keepalive';
import cache from 'memory-cache';
import {S3bucketserviceService} from '../services/s3bucketservice.service';
import {NgbModalConfig, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {EncryptDecryptService} from '../services/encrypt-decrypt.service';
import {Subject} from 'rxjs';
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})


export class MyAccountComponent implements OnInit {
  @ViewChild('closebutton') closebutton1;
  @ViewChild('closebutton') closebutton2;
  @ViewChild('readMore') readMore: ElementRef;

  searchText: string;
  resetForm: FormGroup;
  otpForm: FormGroup;
  editProfileForm: FormGroup;
  editProfileOtpForm: FormGroup;
  editRejectedProfileForm: FormGroup;

  isProfile = false;
  isOrders = false;
  isMyCards = false;
  isOverview = true;

  // Email verification modal steps
  isEmailVerifyStep1 = true;
  isEmailVerifyStep2 = false;

  // Profile edit modal steps
  isEditStep1 = true;
  isEditStep2 = false;

  loadingBtn;

  listGroupItemClass = 'list-group-item';
  activeOverview = 'active';
  activeProfile = '';
  activeOrder = '';
  activeMyCard = '';
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  displayName: string;

  newPassword: string;
  OTPpassword: string;
  confirmNewPassword: string;

  otpEmail: number;
  userSlug: string;
  firstName: string;
  lastName: string;
  phone: string;
  phoneNumber: string;
  address: string;
  email: string;
  otp: number;
  rejectedFirstName: string;
  rejectedLastName: string;
  rejectedPhone: string;
  rejectedAddress: string;
  customerNIC: string;
  nic: string;
  rejectedEmail: string;
  myCards = [];
  cardID: string;
  isDefault = false;
  orderCount: number;

  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  customerMobile: string;
  customerNICNo: string;
  customerNicCopy: string;
  customerAddress: string;
  verificationCode: string;
  customerEmailVerified: number;

  responseDataArray: any;
  overviewResponseDataArray: any;
  allResponseArray: any;
  overviewResponseArray: any;
  orderInstallmentArray: any;
  latePaymentArray: any;
  orderNumber: string;
  orderedDate: Date;
  orderStatus: number;
  merchantName: string;
  orderAmount: number;
  totalInstallments: number;
  outstandingAmount: number;
  remainInstallments: number;
  installmentDate: Date;
  amount: number;
  paidDate: Date;
  installmentStatus: number;
  orderRefNumber: string;
  avilableCredit: string;
  nextInstallmentDate: string;
  slug: string;
  verification: string;
  blocked: string;

  orderID: string;
  accessToken: string;
  isApproved: string;
  isNotApprovedViewed: string;
  isApprovedViewed: string;
  rejectIssues = [];

  fileToUpload: File = null;
  backFileToUpload: File = null;
  imageName: string;
  imageName1: string;
  imageSize: number;
  fileToUploadDriving: File = null;
  fileToUploadPassport: File = null;
  imageNameDriving: string;
  imageNamePassport: string;
  commonImageName: string;
  backName: string;
  radioValue: string;
  nicNum: string;
  documentName: string;
  documentBackName: string;
  closeResult: string;
  modalContent: any;
  resendCount: number = 0;
  modalRef: NgbModalRef;
  encryptedAccessToken;
  count: number;
  currentPage = 1;
  itemsPerPage = 5;
  pageSize: number;
  collectionSize: number;
  bsRangeValue: Date[];
  bsValue = new Date();
  maxDate = new Date();
  minDate = new Date();
  startDate: string = null;
  endDate: string = null;
  showResendOtp: boolean = false;
// Must be declared as "any", not as "DataTables.Settings"
  dtOptions: any;
  dtOptionsRT: any;

  // Hide table until data sync
  showTable = false;
  private tokenizeCardURL = 'http://payment.retailbizz.io/api/ipg-redirect';

  private notifier: NotifierService;

  constructor(private router: Router, private http: HttpClient, private signUpService: SignUpService,
              private tokenService: TokenService, notifier: NotifierService, private loginService: LoginService,
              private idle: Idle, private keepalive: Keepalive,
              private myAccountService: MyAccountService, private cardService: CardService,
              private S3bucketservice: S3bucketserviceService, config: NgbModalConfig,
              private modalService: NgbModal, private encryptDecryptService: EncryptDecryptService,
              private datePipe: DatePipe) {
    this.notifier = notifier;
    this.minDate.setDate(this.minDate.getDate() - 1);
    this.maxDate.setDate(this.maxDate.getDate() + 7);
    this.bsRangeValue = [this.bsValue, this.maxDate];
    // this.amplifyService.authStateChange$
    //   .subscribe(authState => {
    //     if (authState.state === 'signedOut') {
    //       this.router.navigate(['/login']);
    //     }
    //   });

    // sets an idle timeout of 20 minutes, for testing purposes.
    idle.setIdle(1200);
    // sets a timeout period of 20 minutes. after 10 seconds of inactivity, the user will be considered timed out.
    idle.setTimeout(1200);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Signed Out';
      this.timedOut = true;

      // add sign out function from nav
      this.signOut();
    });

    // sets the ping interval to 1 hour
    keepalive.interval(3600);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.reset();

    config.backdrop = 'static';
    config.keyboard = false;

    if (cache.get('slug') === null && localStorage.getItem('slug') == null) {
      this.router.navigate(['/login']);
    } else {
      this.router.navigate(['/profile']);
    }

  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  ngOnInit() {
    // Datatable Responsive
    this.dtOptions = {
      responsive: true,
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50],
      processing: true,
      ordering: false
    };

    // For notifications table
    this.dtOptionsRT = {
      responsive: true,
      pagingType: 'full_numbers',
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50],
      processing: true,
      ordering: false,
      paging: false,
      info: false,
      searchable: false,
      bFilter: false
    };
    this.displayName = localStorage.getItem('displayName');
    this.isApproved = localStorage.getItem('isApproved');

    const isApprViewed = localStorage.getItem('isApprovedViewed');
    const isNotApprViewed = localStorage.getItem('isNotApprovedViewed');

    if (this.isApproved === '1' && (isApprViewed !== 'true' || isApprViewed === undefined)) {
      this.isApprovedViewed = 'false';
      localStorage.setItem('isApprovedViewed', 'false');
    } else if (this.isApproved === '2' && (isNotApprViewed === 'true' || isNotApprViewed === undefined)) {
      this.isNotApprovedViewed = 'false';
      localStorage.setItem('isNotApprovedViewed', 'false');
    } else {
      this.isApprovedViewed = 'true';
      this.isNotApprovedViewed = 'true';
    }

    // Verify Email OTP Validation
    this.otpForm = new FormGroup({
      otpEmail: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')])
    });

    // Edit Profile Form Validation
    this.editProfileForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(3)]),
      phone: new FormControl('', [Validators.required, Validators.pattern('^(07)?(7)?[0-9]{8}$')]),
      address: new FormControl('', [Validators.required, Validators.minLength(3)])
    });

    this.editRejectedProfileForm = new FormGroup({
      rejectedFirstName: new FormControl('', [Validators.minLength(3)]),
      rejectedLastName: new FormControl('', [Validators.minLength(3)]),
      rejectedPhone: new FormControl('', [Validators.pattern('^(07)?(7)?[0-9]{8}$')]),
      rejectedAddress: new FormControl('', [Validators.minLength(3)]),
      rejectedPassportUpload: new FormControl(),
      radioValue: new FormControl(),
      nicNum: new FormControl(),
      imageOneUpload: new FormControl(),
      imageTwoUpload: new FormControl(),

      documentName: new FormControl(),
      documentBackName: new FormControl(),
      rejectedEmail: new FormControl('', [Validators.email, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$')]),
    });

    // Edit Profile OTP Form Validation
    this.editProfileOtpForm = new FormGroup({
      otp: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')])
    });

    // Profile-Change Password Modal Step1 Validation
    this.resetForm = new FormGroup({
      otp: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')]),
      newPassword: new FormControl('', [Validators.required, Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'), this.passwordCheck]),
      confirmPassword: new FormControl('', [Validators.required, this.passwordCheck])
    });
    this.resetForm.controls.newPassword.valueChanges.subscribe(
      x => this.resetForm.controls.confirmPassword.updateValueAndValidity()
    );

    this.viewProfile();
    this.viewOverview();
    this.getOrders(this.startDate, this.endDate);
    this.collectionSize = Number(localStorage.getItem('ordersLength'));
    $('.main').addClass('d-none');
    $('.profile').removeClass('d-none');
    $('.container .nav-form').removeClass('d-md-block d-lg-block');
    $('.container .nav-form').addClass('d-md-flex d-lg-flex');
  }

  // Overview Click
  clickOverview() {
    this.viewOverview();
    this.isOverview = true;
    this.isProfile = false;
    this.isOrders = false;
    this.isMyCards = false;
    this.activeOverview = 'active';
    this.activeProfile = '';
    this.activeOrder = '';
    this.activeMyCard = '';
    this.showTable = false;

  }

  // Profile Click
  clickProfile() {
    this.viewProfile();
    this.isProfile = true;
    this.isOrders = false;
    this.isMyCards = false;
    this.isOverview = false;
    this.activeProfile = 'active';
    this.activeOrder = '';
    this.activeMyCard = '';
    this.activeOverview = '';
  }

  // Orders Click
  clickOrders() {
    this.startDate = null;
    this.endDate = null;
    this.getOrders(this.startDate, this.endDate);
    this.isProfile = false;
    this.isOrders = true;
    this.isMyCards = false;
    this.isOverview = false;
    this.activeProfile = '';
    this.activeOrder = 'active';
    this.activeMyCard = '';
    this.activeOverview = '';
    this.showTable = false;
  }

  collapsed(element, orderRef) {
    this.loadingBtn = element;
    if (document.getElementById('showDetail-' + orderRef).classList.contains('show')) {
      this.loadingBtn.innerHTML = 'Show Details';
    } else {
      this.loadingBtn.innerHTML = 'Hide Details';
    }
  }

  onPageChange(pageNum: number): void {
    this.pageSize = this.itemsPerPage * (pageNum - 1);
  }

  openConfirmation(confirmationDialog, orders) {
    this.modalContent = orders;
    this.modalService.open(confirmationDialog, {ariaLabelledBy: 'modal-basic-title'});
  }

  getOrdersByDateRange($event) {
    this.startDate = this.datePipe.transform($event[0], 'yyyy-MM-dd');
    this.endDate = this.datePipe.transform($event[1], 'yyyy-MM-dd');
    this.getOrders(this.startDate, this.endDate);
  }

  // My Card Click
  clickMyCard() {
    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.getMyCards(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.getMyCards(this.accessToken);
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  getMyCards(accessToken) {

    this.isProfile = false;
    this.isOrders = false;
    this.isMyCards = true;
    this.isOverview = false;
    this.activeProfile = '';
    this.activeOrder = '';
    this.activeMyCard = 'active';
    this.activeOverview = '';

    this.userSlug = localStorage.getItem('slug');
    this.myAccountService.viewCards(accessToken, this.userSlug).subscribe(Response => {
      if (Response.successCode) {
        this.myCards.length = 0;
        this.myCards.push(Response.data);
        this.myCards = this.myCards[0];

        // Don't let to remove if there is only 1 card
        if (this.myCards.length === 1) {
          this.isDefault = true;
        } else {
          this.isDefault = false;
        }
      } else {
        this.notifier.notify('error', Response.message);
        // console.log(Response);
      }
    });
  }

  // Password matching check
  passwordCheck(control) {
    if (control.value != null) {
      const confirmPassword = control.value;
      const pass = control.root.get('newPassword');
      if (pass) {
        const password = pass.value;
        if (confirmPassword !== '' && password !== '') {
          if (confirmPassword !== password) {
            return {
              passwordValidity: true
            };
          } else {
            return null;
          }
        }
      }
    }
  }

  // Send OTP to Verify Email
  sendOtp() {
    //reset form
    this.otpForm.reset();
    setTimeout(() => {
      this.showResendOtp = true;
    }, 180000)
    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      this.email = localStorage.getItem('email');
      this.userSlug = localStorage.getItem('slug');
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.sendOtpWithAccessToken(this.accessToken, this.email);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.sendOtpWithAccessToken(this.accessToken, this.email);
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });

  }

  sendOtpWithAccessToken(accessToken, email) {
    this.myAccountService.sendOtp(accessToken, email).subscribe(Response => {
      if (Response.successCode) {
        this.verificationCode = Response.data;
        localStorage.setItem('verification', Response.data);
        localStorage.setItem('resentVerification', Response.data);
        this.email = localStorage.getItem('email');
        localStorage.setItem('resentEmail', this.email);
        this.verifyEmail(this.verificationCode);
      } else {
        this.notifier.notify('error', Response.message);
        // console.log(Response);
      }
    });
  }

  // Verify email function
  verifyEmail(verification) {
    this.notifier.notify('success', 'Please check your email');
  }

  // Verify email success function
  verifySuccess(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;

    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.verifySuccessWithAccessToken(this.accessToken, this.otpEmail, localStorage.getItem('verification'));
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.verifySuccessWithAccessToken(this.accessToken, this.otpEmail, localStorage.getItem('verification'));
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  verifySuccessWithAccessToken(accessToken, otpEmail, verification) {
    this.myAccountService.verifyEmail(accessToken, otpEmail, verification).subscribe(Response => {
      if (Response.successCode) {
        this.isEmailVerifyStep1 = false;
        this.isEmailVerifyStep2 = true;
        setTimeout(function () {
          location.reload();
        }, 1500);
      } else {
        this.viewProfile();
        this.notifier.notify('error', Response.message);
        // console.log(Response);
        setTimeout(() => {
          this.loadingBtn.innerHTML = 'SUBMIT';
          this.loadingBtn.disabled = false;
        }, 500);
      }
    });
  }

  // Edit profile step 1
  editProfileStep1(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;

    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      this.userSlug = localStorage.getItem('slug');
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.updateCustomerWithAccessToken(this.accessToken, this.userSlug, this.firstName, this.lastName, this.address, this.phone);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.updateCustomerWithAccessToken(this.accessToken, this.userSlug, this.firstName, this.lastName, this.address, this.phone);
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  updateCustomerWithAccessToken(accessToken, userSlug, firstName, lastName, address, phone) {
    if (phone.charAt(0) == '0') {
      this.phone = phone.substring(1);
    } else {
      this.phone = phone;
    }

    this.phoneNumber = localStorage.getItem('phone');

    if (this.phoneNumber === this.phone) {
      this.myAccountService.updateCustomer(accessToken, userSlug, firstName, lastName, address, this.phone).subscribe(Response => {
        if (Response.successCode) {
          localStorage.setItem('displayName', firstName);
          setTimeout(() => {
            this.isEditStep1 = false;
            this.isEditStep2 = false;
            // this.closebutton1.nativeElement.click();
            this.notifier.notify('success', 'Profile info updated successfully');
            location.reload();
          }, 1000);
          // setTimeout(() => {
          //   this.isEditStep1 = true;
          //   this.isEditStep2 = false;
          //   location.reload();
          // }, 2000);
        } else {
          this.notifier.notify('error', Response.message);
          // console.log(Response);
          setTimeout(() => {
            this.loadingBtn.innerHTML = 'NEXT';
            this.loadingBtn.disabled = false;
          }, 500);
        }
      });
    } else {
      this.myAccountService.updateCustomer(accessToken, userSlug, firstName, lastName, address, this.phone).subscribe(Response => {
        if (Response.successCode) {
          localStorage.setItem('phoneVerification', Response.data);
          localStorage.setItem('displayName', firstName);
          localStorage.setItem('resentMobile', this.phone);
          this.isEditStep1 = false;
          this.isEditStep2 = true;
          setTimeout(() => {
            this.showResendOtp = true;
          }, 180000)

        } else {
          this.notifier.notify('error', Response.message);
          // console.log(Response);
          setTimeout(() => {
            this.loadingBtn.innerHTML = 'NEXT';
            this.loadingBtn.disabled = false;
          }, 500);
        }
      });
    }
  }

  // Edit profile step 2

  editProfileStep2(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;
    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.updatePhoneNumberWithAccessToken(this.accessToken, this.otp, localStorage.getItem('phoneVerification'));
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.updatePhoneNumberWithAccessToken(this.accessToken, this.otp, localStorage.getItem('phoneVerification'));
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }


  updatePhoneNumberWithAccessToken(accessToken, OTP, verificationCode) {
    this.myAccountService.verifyPhoneNumber(accessToken, OTP, verificationCode).subscribe(Response => {
      if (Response.successCode) {
        this.notifier.notify('success', Response.message);
        setTimeout(function () {
          location.reload();
        }, 1500);
      } else {
        this.viewProfile();
        this.notifier.notify('error', Response.message);
        // console.log(Response);
        setTimeout(() => {
          this.loadingBtn.innerHTML = 'SUBMIT';
          this.loadingBtn.disabled = false;
        }, 500);
      }
    });

  }

  // editProfileStep2(element) {
  //   this.loadingBtn = element;
  //   this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
  //   this.loadingBtn.disabled = false;
  //   setTimeout(() => {
  //     this.isEditStep1 = false;
  //     this.isEditStep2 = false;
  //   },500);

  // }

  // Edit profile previous step
  prevStep1(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = false;
    setTimeout(() => {
      this.isEditStep1 = true;
      this.isEditStep2 = false;
    }, 500);
  }

  viewOverview() {
    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.viewOverviewWithAccessToken(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });


        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.viewOverviewWithAccessToken(this.accessToken);
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  viewOverviewWithAccessToken(accessToken) {
    this.userSlug = localStorage.getItem('slug');
    // this.userSlug = this.encryptDecryptService.set(this.userSlug);
    this.showTable = false;
    this.myAccountService.viewOverview(accessToken, this.userSlug).subscribe(Response => {
      if (Response.successCode) {
        this.overviewResponseArray = Response.data;
        if (this.overviewResponseArray.length === 0) {
          this.overviewResponseDataArray = [];
        } else {
          this.overviewResponseDataArray = Response.data;
        }
        this.showTable = true;
        localStorage.setItem('ordersLength', this.overviewResponseDataArray.length);
        localStorage.setItem('orders', this.overviewResponseDataArray);

      } else {
        this.notifier.notify('error', Response.message);
        // console.log(Response);
      }
    });
  }


  viewProfile() {
    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.viewProfileWithAccessToken(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });


        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.viewProfileWithAccessToken(this.accessToken);
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  viewProfileWithAccessToken(accessToken) {
    this.userSlug = localStorage.getItem('slug');

    // this.userSlug = this.encryptDecryptService.set(this.userSlug);

    this.myAccountService.viewProfile(accessToken, this.userSlug).subscribe(Response => {
      if (Response.successCode) {
        if (Response.data['users']['is_block'] == 1) {
          this.notifier.notify('error', Response.data['users']['block_reason']);
          setTimeout(() => {
            location.href = '/login';
            this.signOut();
          }, 500);
        } else {
          this.customerFirstName = Response.data['users']['first_name'];
          this.customerLastName = Response.data['users']['last_name'];
          this.customerEmail = Response.data['users']['email'];
          this.customerMobile = Response.data['users']['contact_no'];
          this.customerAddress = Response.data['users']['address'];
          this.customerNIC = Response.data['users']['nic_number'];
          this.customerEmailVerified = Response.data['users']['is_verified'];
          this.avilableCredit = Response.data['users']['available_credit_limit'];
          this.rejectIssues = Response.data['reject_issues'];
          localStorage.setItem('customerIsBlock', Response.data['users']['is_block']);
          localStorage.setItem('email', Response.data['users']['email']);
          localStorage.setItem('phone', Response.data['users']['contact_no']);
          localStorage.setItem('isApproved', Response.data['users']['is_approved']);
          if (Response.data['latestOrder'] == null) {
            this.nextInstallmentDate = "N/A";
          } else {
            this.nextInstallmentDate = Response.data['latestOrder']['due_date'];
          }

          this.editProfileForm.patchValue({
            firstName: this.customerFirstName,
            lastName: this.customerLastName,
            phone: this.customerMobile,
            address: this.customerAddress,
          });
          this.editRejectedProfileForm.patchValue({
            rejectedFirstName: this.customerFirstName,
            rejectedLastName: this.customerLastName,
            rejectedPhone: this.customerMobile,
            rejectedAddress: this.customerAddress,
            rejectedEmail: Response.data['users']['email'],
            nicNum: this.customerNIC,
            radioValue: Response.data['users']['document_type_id'],
            documentName: Response.data['users']['document_name'],
            documentBackName: Response.data['users']['back_document_name'],
          });

          if (this.rejectIssues !== []) {
            $('.rejectData').hide();
            Response.data['reject_issues'].forEach(issue => {
              if (issue.issue_code == 'ISSUE001') {
                $('#fName').show();
              } else if (issue.issue_code == 'ISSUE002') {
                $('#lName').show();
              } else if (issue.issue_code == 'ISSUE003') {
                $('#rNic').show();
              } else if (issue.issue_code == 'ISSUE004') {
                $('#rAddress').show();
              } else if (issue.issue_code == 'ISSUE005') {
                $('#mobile').show();
              } else if (issue.issue_code == 'ISSUE006') {
                $('#rEmail').show();
              } else if (issue.issue_code == 'ISSUE007') {
                $('#rNic').show();
              } else if (issue.issue_code == 'ISSUE008') {
                $('#rNic').show();
              } else if (issue.issue_code == 'ISSUE009') {
                $('#imageOne').show();
              } else if (issue.issue_code == 'ISSUE010') {
                $('#imageTwo').show();
              }
            });
          }
        }
      } else {
        this.notifier.notify('error', Response.message);
        // console.log(Response);
      }
    });
  }

  addCardClick(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;
    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.addCard(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.addCard(this.accessToken);
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // add card is same as adding the card in sign up process, therefore using the same function
  addCard(accessToken) {
    this.email = localStorage.getItem('email');
    const promise = this.cardService.generateOrder(accessToken, this.userSlug).toPromise();
    promise.then((Response) => {
      if (Response.successCode) {
        this.orderID = Response.data;
        // Add additional param as 2 to redirect to different page on sign up and profile
        // 1 - sign up
        // 2 - profile
        window.location.href = this.tokenizeCardURL + '/' + this.email + '/' + this.orderID + '/2';
      } else {
        this.notifier.notify('error', Response.message);
      }
    }).catch((error) => {
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  getOrders(startDate, endDate) {

    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      this.email = localStorage.getItem('email');
      this.userSlug = localStorage.getItem('slug');
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.viewOrders(this.accessToken, startDate, endDate);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.viewOrders(this.accessToken, startDate, endDate);
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  viewOrders(accessToken, startDate, endDate) {
    this.showTable = false;
    this.myAccountService.viewMyOrders(accessToken, this.userSlug, startDate, endDate).subscribe(Response => {
      this.allResponseArray = Response;
      if (this.allResponseArray.length === 0) {
        this.responseDataArray = [];
        this.orderInstallmentArray = [];
        this.latePaymentArray = [];
      } else {
        this.responseDataArray = Response[0];
        this.orderInstallmentArray = Response[1];
        this.latePaymentArray = Response[2];
      }
      this.showTable = true;
      localStorage.setItem('ordersLength', this.responseDataArray.length);
      localStorage.setItem('orders', this.responseDataArray);
    });
  }

  // select card to remove tokenized card
  removeCardConfirm(cardCode, orderCount) {
    this.cardID = cardCode;
    this.orderCount = orderCount;
  }

  confirmRemoveCard() {
    if (this.orderCount > 0) {
      this.notifier.notify('error', 'Sorry! You cannot this card, there are associated instalments!');
    } else {
      // check if token exists, if not request access token
      const promise1 = this.tokenService.checkToken().toPromise();
      promise1.then((Response1) => {
        this.email = localStorage.getItem('email');
        this.userSlug = localStorage.getItem('slug');
        if (Response1.data === null) {
          // No valid token exists, so request new
          const promise2 = this.tokenService.requestToken().toPromise();
          promise2.then((Response2) => {
            this.accessToken = Response2.access_token;

            // Save new token
            const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
            promise3.then((Response3) => {
              if (Response3.successCode) {
                this.removeCard(this.accessToken);
              }
            }).catch((error) => {
              this.notifier.notify('error', error);
              console.log('Promise rejected with ' + JSON.stringify(error));
            });

          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });
        } else {
          // valid token returned, use it
          this.accessToken = Response1.data['access_token'];
          this.removeCard(this.accessToken);
        }
      }).catch((error) => {
        this.notifier.notify('error', error);
        console.log('Promise rejected with ' + JSON.stringify(error));
      });
    }
  }

  // remove tokenized card
  removeCard(accessToken) {
    const promise = this.cardService.removeCard(accessToken, this.cardID, this.userSlug).toPromise();
    promise.then((Response) => {
      if (Response.successCode) {
        this.notifier.notify('success', Response.message);
        this.getMyCards(accessToken);
      } else {
        this.notifier.notify('error', Response.message);
      }
    }).catch((error) => {
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  updateDefaultCardConfirm(element, cardCode) {
    this.cardID = cardCode;
    this.loadingBtn = element;
    if (document.getElementById('cardInfoTab' + cardCode).classList.contains('show')) {
      this.loadingBtn.innerHTML = 'Show Details';
    } else {
      this.loadingBtn.innerHTML = 'Hide Details';
    }
  }

  confirmDefaultCard(cardCode) {
    this.cardID = cardCode
    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      this.email = localStorage.getItem('email');
      this.userSlug = localStorage.getItem('slug');
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.updateDetaultCard(this.accessToken, this.cardID);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.updateDetaultCard(this.accessToken, this.cardID);
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // update default tokenized card
  updateDetaultCard(accessToken, cardCode) {
    const promise = this.cardService.updateDefaultCard(accessToken, cardCode, this.userSlug).toPromise();
    promise.then((Response) => {
      if (Response.successCode) {
        this.notifier.notify('success', Response.message);
        this.getMyCards(accessToken);
      } else {
        this.notifier.notify('error', Response.message);
      }
    }).catch((error) => {
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // Get token to call send OTP reset password
  sendOtpResetPassword() {
    //rest form
    this.resetForm.reset();
    setTimeout(() => {
      this.showResendOtp = true;
    }, 180000)
    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.resetPasswordWithAccessToken(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.resetPasswordWithAccessToken(this.accessToken);
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // Send otp from cognito if user exists on our db
  resetPasswordWithAccessToken(accessToken) {
    this.email = localStorage.getItem('email');

    this.loginService.resetPassword(accessToken, this.email).subscribe(Response => {
      if (Response.successCode) {
        this.slug = Response.data['slug'];
        this.loginService.sendOtp(accessToken, this.email).subscribe(Response => {
          if (Response.successCode) {
            localStorage.setItem('resentEmail', this.email);
            localStorage.setItem('resentVerification', Response.data);
            this.notifier.notify('success', 'OTP sent');
            // setTimeout(() => {
            //   this.loadingBtn.innerHTML = 'SUBMIT';
            //   this.loadingBtn.disabled = false;
            // }, 1000);
          }
        })
      } else {
        this.notifier.notify('error', Response.message);
        // console.log('Response Error');
        // setTimeout(() => {
        //   this.loadingBtn.innerHTML = 'SUBMIT';
        //   this.loadingBtn.disabled = false;
        // }, 500);
      }
    });
  }

  // Get token to change password
  changePassword(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;

    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.resetPasswordSubmit(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.resetPasswordSubmit(this.accessToken);
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // submit otp and new password to change password
  resetPasswordSubmit(accessToken) {

    this.userSlug = localStorage.getItem('slug');
    this.verification = localStorage.getItem('resentVerification');
    this.loginService.verifyOtp(accessToken, this.OTPpassword, this.verification).subscribe(Response => {
      if (Response.successCode) {
        this.notifier.notify('success', Response.message);
        this.loginService.resetPasswordSubmit(accessToken, Response.data, this.newPassword, this.confirmNewPassword).subscribe(Response => {
          if (Response.successCode) {
            this.notifier.notify('success', Response.message);
            setTimeout(() => {
              this.loadingBtn.innerHTML = 'CHANGE PASSWORD';
              this.loadingBtn.disabled = false;
              this.signOut();
            }, 500);
          } else {
            this.notifier.notify('error', Response.message);
            // console.log('Response Error');
            setTimeout(() => {
              this.loadingBtn.innerHTML = 'CHANGE PASSWORD';
              this.loadingBtn.disabled = false;
            }, 500);
          }

          setTimeout(() => {
            this.closebutton2.nativeElement.click();
          }, 1000);
        });
      } else {
        this.viewProfile();
        this.notifier.notify('error', Response.message);
        // console.log('Response Error');
        setTimeout(() => {
          this.loadingBtn.innerHTML = 'RESET PASSWORD';
          this.loadingBtn.disabled = false;
        }, 500);
      }

    });

  }

  // Get token to pay now
  payNowClick(element, orderRefNumber) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;

    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      this.orderNumber = orderRefNumber;
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.payNow(this.accessToken, this.orderNumber);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.payNow(this.accessToken, this.orderNumber);
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  payNow(accessToken, orderRefNumber) {
    this.cardService.payNow(accessToken, orderRefNumber).subscribe(Response => {
      if (Response.successCode) {
        this.getOrders(this.startDate, this.endDate);
        this.notifier.notify('success', Response.message);
        this.modalService.dismissAll();
      } else {
        this.notifier.notify('error', Response.message);
        console.log('Response Error');
        setTimeout(() => {
          this.loadingBtn.innerHTML = 'PAY NOW';
          this.loadingBtn.disabled = false;
        }, 500);
      }
    });
  }

  // Resent OTP
  reSendOtp() {
    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();

    promise1.then((Response1) => {

      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.resentOtpWithAccessToken(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });


      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.resentOtpWithAccessToken(this.accessToken);
      }

    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // Initiate forgot password OTP
  resentOtpWithAccessToken(accessToken) {
    this.email = localStorage.getItem('resentEmail');
    this.verification = localStorage.getItem('resentVerification');
    this.resendCount = this.resendCount + 1;
    localStorage.setItem('clickCount', String(this.resendCount));
    this.count = Number(localStorage.getItem('clickCount'));
    if (this.count > 2) {
      this.resendCount = 0;
      this.notifier.notify('error', 'Too many resend attempts! Please try again');
      location.reload();
      localStorage.removeItem('clickCount');
    } else {
      this.loginService.reSendOtp(accessToken, this.email, this.verification).subscribe(Response => {
        if (Response.successCode) {
          setTimeout(() => {
            this.notifier.notify('success', 'Resent OTP');
          }, 1000);
        } else {
          this.notifier.notify('error', Response.message);
          // console.log('Response Error');
          setTimeout(() => {
            this.loadingBtn.innerHTML = 'SUBMIT';
            this.loadingBtn.disabled = false;
          }, 500);
        }
      });
    }

  }


  // Resent OTP
  reSendOtpForContactNumber() {
    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();

    promise1.then((Response1) => {

      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.resentOtpForContactNumberWithAccessToken(this.accessToken);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });

        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });


      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.resentOtpForContactNumberWithAccessToken(this.accessToken);
      }

    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  // Initiate forgot password OTP
  resentOtpForContactNumberWithAccessToken(accessToken) {
    this.phoneNumber = localStorage.getItem('resentMobile');
    this.verification = localStorage.getItem('phoneVerification');
    this.resendCount = this.resendCount + 1;
    localStorage.setItem('clickCount', String(this.resendCount));
    this.count = Number(localStorage.getItem('clickCount'));
    if (this.count > 2) {
      this.isEditStep1 = true;
      this.isEditStep2 = false;
      this.resendCount = 0;
      this.notifier.notify('error', 'Too many resend attempts! Please try again');
      localStorage.removeItem('clickCount');
    } else {
      this.loginService.reSendOtpForMobile(accessToken, this.phoneNumber, this.verification).subscribe(Response => {
        if (Response.successCode) {
          setTimeout(() => {
            this.notifier.notify('success', 'Resent OTP');
          }, 1000);
        } else {
          this.notifier.notify('error', Response.message);
          // console.log('Response Error');
          setTimeout(() => {
            this.loadingBtn.innerHTML = 'SUBMIT';
            this.loadingBtn.disabled = false;
          }, 500);
        }
      });
    }
  }

  signOut() {
    cache.del('displayName');
    cache.del('slug');
    // cache.clear();

    localStorage.clear();
    location.href = '';

    localStorage.setItem('isApprovedViewed', 'true');
    localStorage.setItem('isNotApprovedViewed', 'true');
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    console.log(this.fileToUpload);
    this.imageName = this.fileToUpload.name;
    // this.imageName1 = this.fileToUpload.name;
    this.imageSize = ((this.fileToUpload.size) / (1024 * 1024));
    // this.S3bucketservice.uploadFile(this.fileToUpload);
  }

  handleFileInput1(files: FileList) {
    this.backFileToUpload = files.item(0);
    this.imageName1 = this.backFileToUpload.name;
    this.imageSize = ((this.backFileToUpload.size) / (1024 * 1024));
    // this.S3bucketservice.uploadFile(this.fileToUpload);
  }


  // Edit rejected profile step 1
  editRejectedProfileStep1(element) {
    this.loadingBtn = element;
    this.loadingBtn.innerHTML = '<i class="fa fa-spinner fa-spin mr-2"></i> Please wait...';
    this.loadingBtn.disabled = true;

    if (this.fileToUpload == null && this.backFileToUpload !== null) {
      this.commonImageName = this.documentName;
      this.S3bucketservice.uploadFile(this.backFileToUpload);
      this.backName = this.backFileToUpload.name;
    } else if (this.fileToUpload !== null && this.backFileToUpload == null) {
      this.backName = this.documentBackName;
      this.S3bucketservice.uploadFile(this.fileToUpload);
      this.commonImageName = this.fileToUpload.name;
    } else if (this.backFileToUpload !== null && this.fileToUpload !== null) {
      this.S3bucketservice.uploadFile(this.fileToUpload);
      this.S3bucketservice.uploadFile(this.backFileToUpload);
      this.commonImageName = this.fileToUpload.name;
      this.backName = this.backFileToUpload.name;
    } else {
      this.commonImageName = this.documentName;
      this.backName = this.documentBackName;
    }
    this.nic = this.nicNum;

    // check if token exists, if not request access token
    const promise1 = this.tokenService.checkToken().toPromise();
    promise1.then((Response1) => {
      this.userSlug = localStorage.getItem('slug');
      if (Response1.data === null) {
        // No valid token exists, so request new
        const promise2 = this.tokenService.requestToken().toPromise();
        promise2.then((Response2) => {
          this.accessToken = Response2.access_token;

          // Save new token
          const promise3 = this.tokenService.saveToken(this.accessToken).toPromise();
          promise3.then((Response3) => {
            if (Response3.successCode) {
              this.updateRejectedCustomerWithAccessToken(this.accessToken, this.userSlug, this.rejectedFirstName,
                this.rejectedLastName, this.rejectedAddress, this.rejectedPhone, this.nic, this.commonImageName, this.radioValue, this.backName, this.rejectedEmail);
            }
          }).catch((error) => {
            this.notifier.notify('error', error);
            console.log('Promise rejected with ' + JSON.stringify(error));
          });
        }).catch((error) => {
          this.notifier.notify('error', error);
          console.log('Promise rejected with ' + JSON.stringify(error));
        });
      } else {
        // valid token returned, use it
        this.accessToken = Response1.data['access_token'];
        this.updateRejectedCustomerWithAccessToken(this.accessToken, this.userSlug, this.rejectedFirstName,
          this.rejectedLastName, this.rejectedAddress, this.rejectedPhone, this.nic, this.commonImageName, this.radioValue, this.backName, this.rejectedEmail);
      }
    }).catch((error) => {
      this.notifier.notify('error', error);
      console.log('Promise rejected with ' + JSON.stringify(error));
    });
  }

  updateRejectedCustomerWithAccessToken(accessToken, userSlug, firstName, lastName, address, phone, nic, nicFile, radioValue, backName, email) {
    this.phoneNumber = localStorage.getItem('phone');
    if (this.phoneNumber === phone) {
      this.myAccountService.updateRejectedCustomer(accessToken, userSlug, firstName, lastName, address, phone, nic, nicFile, radioValue, backName, email).subscribe(Response => {
        if (Response.successCode) {
          localStorage.setItem('displayName', firstName);
          localStorage.setItem('isApproved', '0');
          setTimeout(() => {
            this.isEditStep1 = false;
            this.isEditStep2 = false;
            // this.closebutton1.nativeElement.click();
            this.viewProfile();
            this.notifier.notify('success', 'Profile info updated successfully');
          }, 1000);
          setTimeout(() => {
            this.isEditStep1 = true;
            this.isEditStep2 = false;
            location.reload();
          }, 2000);
        } else {
          this.notifier.notify('error', Response.message);
          // console.log(Response);
          setTimeout(() => {
            this.loadingBtn.innerHTML = 'NEXT';
            this.loadingBtn.disabled = false;
          }, 500);
        }
      });
    } else {
      this.myAccountService.updateRejectedCustomer(accessToken, userSlug, firstName, lastName, address, phone, nic, nicFile, radioValue, backName, email).subscribe(Response => {
        if (Response.successCode) {
          localStorage.setItem('phoneVerification', Response.data);
          localStorage.setItem('displayName', firstName);
          this.isEditStep1 = false;
          this.isEditStep2 = true;
          setTimeout(() => {
            this.showResendOtp = true;
          }, 180000)
        } else {
          this.notifier.notify('error', Response.message);
          // console.log(Response);
          setTimeout(() => {
            this.loadingBtn.innerHTML = 'NEXT';
            this.loadingBtn.disabled = false;
          }, 500);
        }
      });
    }
  }
}
