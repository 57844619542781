import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {of, BehaviorSubject} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {Response} from '../models/response';

@Injectable({
  providedIn: 'root'
})
export class MyAccountService {

  httpOptions: any;
  private viewProfileURL = 'https://apiread.retailbizz.io/api/customer/view-profile';
  private viewCardsURL = 'https://apiread.retailbizz.io/api/card/get-card';
  private sendOTPURL = 'https://apiwrite.retailbizz.io/api/customer/send-otp';
  private verifyEmailURL = 'https://apiwrite.retailbizz.io/api/customer/verify-email';
  private viewOrdersURL = 'https://apiread.retailbizz.io/api/order/view-orders';
  private reSendOTPURL = 'https://apiwrite.retailbizz.io/api/customer/re-send-otp';
  private updateCustomerURL = 'https://apiwrite.retailbizz.io/api/customer/update-customer';
  private verifyPhoneNumberUrl = 'https://apiwrite.retailbizz.io/api/customer/verify-update-otp';
  private verifyOTPURL = 'https://apiwrite.retailbizz.io/api/customer/verify-otp';
  private viewOverviewURL = 'https://apiread.retailbizz.io/api/order/view-recent-transactions';

  accessToken: string;
  hsh: string;
  userSlug: string;

  constructor(private http: HttpClient) {
    this.httpOptions = {
      headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'application/json')
    };
  }

  viewOverview(accessToken, userSlug) {
    return this.http.post<Response>(this.viewOverviewURL, {
      user_slug: userSlug,
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  viewProfile(accessToken, userSlug) {
    return this.http.post<Response>(this.viewProfileURL, {
      user_slug: userSlug,
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  viewCards(accessToken, userSlug) {
    return this.http.post<Response>(this.viewCardsURL, {
      slug: userSlug,
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  sendOtp(accessToken, cusEmail) {
    return this.http.post<Response>(this.sendOTPURL, {
      email: cusEmail,
      user_type: 1
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  verifyEmail(accessToken, OTP, verificationCode) {
    return this.http.post<Response>(this.verifyEmailURL, {
      otp: OTP,
      verification_code: verificationCode,
      user_type: 1
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  reSendOtp(accessToken, email, verificationCode) {
    return this.http.post<Response>(this.reSendOTPURL, {
      email: email,
      verification_code: verificationCode,
      user_type: 1
    }, { headers: { Authorization: 'Bearer ' + accessToken } })
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  updateCustomer(accessToken, slug, firstName, lastName, address, contactNo) {
    return this.http.post<Response>(this.updateCustomerURL, {
      slug: slug,
      first_name: firstName,
      last_name: lastName,
      address: address,
      contact_no: contactNo,
      nic_copy_name: null,
      back_nic_copy_name: null,
      nic_driving_license: null,
      document_type: null
    }, { headers: { Authorization: 'Bearer ' + accessToken } })
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  updateRejectedCustomer(accessToken, slug, firstName, lastName, address, contactNo, nic, nicFile, radioValue, backName, email) {
    return this.http.post<Response>(this.updateCustomerURL, {
      slug: slug,
      first_name: firstName,
      last_name: lastName,
      address: address,
      contact_no: contactNo,
      nic_copy_name: nicFile,
      back_nic_copy_name: backName,
      nic_driving_license: nic,
      document_type: radioValue,
      email: email
    }, { headers: { Authorization: 'Bearer ' + accessToken } })
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  verifyPhoneNumber(accessToken, OTP, verificationCode) {
    // Get access token

    // this.accessToken = localStorage.getItem('portNum');
    return this.http.post<Response>(this.verifyPhoneNumberUrl, {
      otp: OTP,
      verification_code: verificationCode,
    }, { headers: { Authorization: 'Bearer ' + accessToken } })
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  viewMyOrders(accessToken, userSlug, startDate, endDate) {

    return this.http.post<Response>(this.viewOrdersURL, {
      user_slug: userSlug,
      start_date: startDate,
      end_date: endDate
    }, {headers: {Authorization: 'Bearer ' + accessToken}})
      .pipe(tap(resp => {
        return resp;
      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }

  verifyOtp(accessToken, otp, verificationCode) {
    return this.http.post<Response>(this.verifyOTPURL, {
      otp: otp,
      verification_code: verificationCode
    }, { headers: { Authorization: 'Bearer ' + accessToken } })
      .pipe(tap(resp => {
        return resp;
        // console.error(resp);

      }), catchError((err) => {
        console.error(err);
        throw err;
      }));
  }
}
