
<div class="container">
  <div class="row">
    <div class="col">
      <div class="mobile-wrapper text-center mt-5">
        <div class="title">
          Add card failed!
        </div>
        <div class="info p-2 mt-2">
          Either your card is associated with another account or something went wrong. Please try again.
        </div>
        <div class="img-fluid">
          <img src="./assets/images/add-card-failed.png" class="img-fluid mt-5 mb-5" alt="Card save success">
        </div>
        <p class="card-text">
          <i class="fas fa-info-circle"></i> <small class="ml-2 font-weight-bold text-muted">Please click back on your browser window to return back to the app</small>
        </p>

      </div>
    </div>
  </div>
</div>
