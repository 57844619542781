<app-navigation></app-navigation>
<div class="content-sec">
  <div class="container">

    <!--  PAYMENT ACKNOWLEDGEMENT  -->
    <div class="row no-gutters acc-success-gutter">
      <div class="col-md-6 mx-auto">
        <div class="card shadow-lg border-md-0 mb-3 ml-3 mr-3 mb-5">

          <div class="card-body text-center p-3 ml-md-0 mr-md-0 ml-lg-5 mr-lg-5 mt-0 mt-md-3">
            <form class="ml-md-5 mr-md-5 mt-5 mb-2">
              <div class="badge-msg row mr-sm-3 ml-sm-3 d-flex">
                <span class="badge d-block m-auto badge-pill badge-success"><i class="fas fa-check fa-2x"></i></span>
              </div>

              <h5 class="ml-md-0 mr-md-0 mb-1 mt-4">Success</h5>
              <p class="card-text mt-2">Your card is added to your account successfully.</p>

              <button type="submit" class="btn btn-primary w-100 p-2 mb-4 mt-1" routerLink="/profile">Back to Profile
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
